import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-modal', (element: Element) => {
    import (
        /* webpackChunkName: "modal" */
        './components/modal.component')
        .then(({ ModalComponent }) => {
            <Component> ModalComponent.loadByElement(element);
        });
});

componentService.register('.js-open-as-modal', (element: Element) => {
    import (
        /* webpackChunkName: "modal-link" */
        './components/modal-link.component')
        .then(({ ModalLinkComponent }) => {
            <Component> ModalLinkComponent.loadByElement(element);
        });
});

services.register('modal', () => {
    return import (
        /* webpackChunkName: "modal-service" */
        './services/modal.service')
        .then(({ modalService }) => {
            return modalService;
        });
});
