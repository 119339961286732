import { Component, componentService } from '@contorion/core';

componentService.register('[scroll-to]', (element: Element) => {
    import (
        /* webpackChunkName: "scroll-to" */
        './components/scroll-to.component')
        .then(({ ScrollToComponent }) => {
            <Component> ScrollToComponent.loadByElement(element);
        });
});
