import { Component, componentService } from '@contorion/core';

componentService.register('.js-progress-bar-header', (element: Element) => {
    import (
        /* webpackChunkName: "progress-bar-header" */
        './components/progress-bar-header.component')
        .then(({ ProgressBarHeaderComponent }) => {
            <Component> ProgressBarHeaderComponent.loadByElement(element);
        });
});
