import * as PromisePolyfill from 'promise-polyfill';

interface IPromisedWindow extends Window {
    Promise: any;
}

(function(promisedWindow: IPromisedWindow) {
    if (typeof Promise === 'undefined') {
        promisedWindow.Promise = PromisePolyfill;
    }
})(<IPromisedWindow> window);
