import { Component, componentService } from '@contorion/core';

componentService.register('.js-markup-block', (element: Element) => {
    import (
        /* webpackChunkName: "markup-block" */
        './components/markup-block.component'
        )
        .then(({ MarkupBlockComponent }) => {
            <Component> MarkupBlockComponent.loadByElement(element);
        });
});

componentService.register('.js-styles-block', (element: Element) => {
    import (
        /* webpackChunkName: "styles-block" */
        './components/styles-block.component'
        )
        .then(({ StylesBlockComponent }) => {
            <Component> StylesBlockComponent.loadByElement(element);
        });
});
