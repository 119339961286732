export const isElementInViewPort = (element: HTMLElement): boolean => {
    const rect: ClientRect = element?.getBoundingClientRect();
    const vWidth: number = window.innerWidth || document.documentElement.clientWidth;
    const vHeight: number = window.innerHeight || document.documentElement.clientHeight;
    const efp: Function =
        (x: number, y: number): Element => {
            return document.elementFromPoint(x, y);
        };

    if (!rect || rect.right < 0 || rect.bottom < 0
        || rect.left > vWidth || rect.top > vHeight) {
        return false;
    }

    return (
        element.contains(efp(rect.left, rect.top))
        || element.contains(efp(rect.right, rect.top))
        || element.contains(efp(rect.right, rect.bottom))
        || element.contains(efp(rect.left, rect.bottom))
    );
};
