import { Component, componentService } from '@contorion/core';

componentService.register('.js-billie', (element: Element) => {
    import (
        /* webpackChunkName: "billie" */
        './components/billie.component')
        .then(({ BillieComponent }) => {
            <Component> BillieComponent.loadByElement(element);
        });
});
