import { variationService } from '../services';
import { Component } from './component';

const VARIATION_DEFAULT: string = 'control';
const CLASS_HIDDEN: string = 'hidden';

export abstract class VariationComponent extends Component {
    protected get targetClass(): string {
        return this.element.dataset.targetClass || CLASS_HIDDEN;
    }

    protected get variationName(): string {
        return VARIATION_DEFAULT;
    }

    protected get campaignName(): string {
        return null;
    }

    protected get controlElements(): Array<HTMLElement> {
        return null;
    }

    protected get isCurrentVariation(): boolean {
        let foundCampaign = false;
        for (const currentVariation of variationService.getCurrentVariations()) {
            if (currentVariation.campaignName !== this.campaignName) {
                continue;
            }

            foundCampaign = true;

            if (currentVariation.variationName === this.variationName) {
                return true;
            }
        }

        return !foundCampaign && this.variationName === VARIATION_DEFAULT;
    }

    constructor(protected element: HTMLElement) {
        super(element);
        variationService.subscribeCurrentVariation(() => {
            this.onVariationChange();
        });
    }

    public enable(): void {
        this.element.classList.remove(this.targetClass);
    }

    public disable(): void {
        this.element.classList.add(this.targetClass);
    }

    public disableControl(): void {
        if (!this.controlElements) {
            return;
        }

        for (const elem of this.controlElements) {
            elem.classList.add(this.targetClass);
        }
    }

    protected onVariationChange(): void {
        if (this.isCurrentVariation) {
            this.disableControl();
            this.enable();

            return;
        }

        this.disable();
    }
}
