import { Component } from './component';
import { element as $, isElementInViewPort } from '../helpers';

const CLASS_HIDDEN: string = 'hidden';
const CLASS_TOUCHED: string = 'touched';
const CLASS_VALID: string = 'valid';
const CLASS_INVALID: string = 'invalid';
const CLASS_EMPTY: string = 'empty';
const CLASS_TOOLTIP_ERROR: string = 'tooltip--error';
const CLASS_TOOLTIP_WARNING: string = 'tooltip--warning';
const CLASS_TOOLTIP_HELP: string = 'tooltip--help';
const TIMEOUT_REPORT_VALIDITY: number = 300;
const TIMEOUT_HELP_VISIBILITY_HIDE: number = 800;

export type FormControlElementType = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export class FormElementComponent extends Component {
    protected customError: string;

    protected warning: string;

    protected reportValidityTimeout: number;

    protected hideHelpWithDelay: number;

    private errorsAndWarnnigs: Array<HTMLElement> = [];

    protected get tooltipErrors(): HTMLElement {
        return this.findTooltip(this.element);
    }

    protected get tooltipWarnings(): HTMLElement {
        return this.findTooltip(this.element, CLASS_TOOLTIP_WARNING);
    }

    protected get tooltipHelp(): HTMLElement {
        return this.findTooltip(this.element, CLASS_TOOLTIP_HELP);
    }

    protected get errorMessages(): Array<HTMLElement> {
        if (!this.tooltipErrors) {
            return [];
        }

        return Array.apply(null, this.tooltipErrors.children)
            .map((child: HTMLElement): HTMLElement => child);
    }

    protected get warningMessages(): Array<HTMLElement> {
        if (!this.tooltipWarnings) {
            return [];
        }

        return Array.apply(null, this.tooltipWarnings.children)
            .map((child: HTMLElement): HTMLElement => child);
    }

    constructor(protected element: FormControlElementType) {
        super(element);

        element.addEventListener('change', this.onChange.bind(this));
        element.addEventListener('focus', this.onFocus.bind(this));
        element.addEventListener('blur', this.onBlur.bind(this));
        element.addEventListener('keyup', this.onKeyUp.bind(this));

        this.updateClasses();
    }

    public isValid(): boolean {
        if (this.element instanceof HTMLSelectElement) {
            return this.element.validity.valid;
        }

        return this.element.readOnly || this.element.validity.valid;
    }

    public reportValidity(): void {
        let errorType: string = null;
        const { validity } = this.element;

        switch (true) {
            case validity.valueMissing:
                errorType = 'required';

                break;
            case validity.patternMismatch:
                errorType = 'pattern';

                break;
            case validity.typeMismatch:
                errorType = this.element.getAttribute('type');

                break;
            case validity.rangeOverflow:
                errorType = 'rangeOverFlow';

                break;
            case validity.rangeUnderflow:
                errorType = 'rangeUnderFlow';

                break;
            case validity.customError:
                errorType = this.customError;

                break;
            case validity.valid:
                return;
            default:
                console && console.log && console.log('Could not determine the error type',
                    validity);

                break;
        }

        this.showError(errorType);

        if (!isElementInViewPort(this.errorsAndWarnnigs[0])) {
            this.scrollToElement(this.errorsAndWarnnigs[0]);
        }
    }

    protected hideTooltip(tooltip: HTMLElement, messages: Array<HTMLElement>): void {
        if (!tooltip) {
            return;
        }

        tooltip.classList.add(CLASS_HIDDEN);

        for (const message of messages) {
            message.classList.add(CLASS_HIDDEN);
        }
    }

    protected showTooltip(tooltip: HTMLElement, messages: Array<HTMLElement>, type: string): void {
        if (!tooltip) {
            return;
        }

        this.hideTooltip(tooltip, messages);

        for (const message of messages) {
            if (message.dataset.message !== type) {
                continue;
            }

            message.classList.remove(CLASS_HIDDEN);
            tooltip.classList.remove(CLASS_HIDDEN);
            this.errorsAndWarnnigs.push(tooltip);
        }
    }

    public hideError(): void {
        this.hideTooltip(this.tooltipErrors, this.errorMessages);
    }

    public hideWarning(): void {
        this.hideTooltip(this.tooltipWarnings, this.warningMessages);
    }

    public showError(type: string): void {
        this.showTooltip(this.tooltipErrors, this.errorMessages, type);
    }

    public showWarning(type: string): void {
        this.showTooltip(this.tooltipWarnings, this.warningMessages, type);
    }

    public showHelp(): void {
        if (!this.tooltipHelp) {
            return;
        }
        this.tooltipHelp.classList.remove(CLASS_HIDDEN);
    }

    public hideHelp(): void {
        if (!this.tooltipHelp) {
            return;
        }
        this.tooltipHelp.classList.add(CLASS_HIDDEN);
    }

    public getCustomerError(): string {
        return this.customError;
    }

    public setCustomError(customError: string) {
        this.customError = customError;
        this.element.setCustomValidity(customError);
        this.updateClasses();
        this.updateHelpVisibility();
    }

    public setWarning(warning: string | null): void {
        this.warning = warning;
    }

    public getWarning(): string {
        return this.warning;
    }

    protected updateClasses(): void {
        if (this.element.value === '') {
            this.element.classList.add(CLASS_EMPTY);
        } else {
            this.element.classList.remove(CLASS_EMPTY);
        }

        if (this.isValid()) {
            this.element.classList.remove(CLASS_INVALID);
            this.element.classList.add(CLASS_VALID);
        } else {
            this.element.classList.add(CLASS_INVALID);
            this.element.classList.remove(CLASS_VALID);
        }
    }

    protected updateHelpVisibility(): void {
        if (document.activeElement !== this.element) {
            return;
        }

        window.clearTimeout(this.hideHelpWithDelay);
        this.errorsAndWarnnigs.length = 0;

        if (this.isValid()) {
           this.hideHelpWithDelay =  window.setTimeout(
                this.hideHelp.bind(this),
                TIMEOUT_HELP_VISIBILITY_HIDE
           );

           return;
        }

        this.showHelp();
    }

    protected findTooltip(
        currentElement: HTMLElement,
        className: string = CLASS_TOOLTIP_ERROR
    ): HTMLElement {
        if (!currentElement.parentElement) {
            return null;
        }

        return <HTMLElement> $(`.${className}`, currentElement.parentElement);
    }

    protected scrollToElement(element: HTMLElement): void {
        if (element === undefined) {
            return;
        }

        const scrollTo = element.getBoundingClientRect().top
            + (window.scrollY % window.innerHeight)
            - (window.innerHeight / 2);

        window.scroll(0, scrollTo);
    }

    protected onChange(): void {
        this.updateClasses();

        if (this.isValid()) {
            this.hideError();
        }
    }

    protected onFocus(): void {
        window.clearTimeout(this.reportValidityTimeout);
        this.showHelp();
        this.hideError();
        this.hideWarning();
    }

    protected onBlur(): void {
        if (!this.element.classList.contains(CLASS_TOUCHED)) {
            this.element.classList.add(CLASS_TOUCHED);
        }

        if (!this.isValid()) {
            this.reportValidityTimeout = window
                .setTimeout(this.reportValidity.bind(this), TIMEOUT_REPORT_VALIDITY);
            this.showHelp();
        }

        this.hideHelp();

        if (this.isValid() && !!this.warning) {
            this.showWarning(this.warning);
        }

        this.updateClasses();
    }

    protected onKeyUp(): void {
        this.updateClasses();
        this.updateHelpVisibility();
    }
}
