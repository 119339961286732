if (typeof Object.assign !== 'function') {
    // must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, 'assign', {
        configurable: true,
        value: function assign(target: any, varArgs: any): any { // .length of function is 2
            'use strict';
            if (target === null) { // if TypeError undefined or null
                throw new TypeError('Cannot convert undefined or null to object');
            }

            const to = Object(target);

            for (let index = 1; index < arguments.length; index++) {
                const nextSource = arguments[index];

                if (nextSource !== null) { // skip over if undefined or null
                    for (const nextKey in nextSource) {
                        // avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }

            return to;
        },
        writable: true,
    });
}
