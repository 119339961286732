export function triggerEvent(element: HTMLDocument | HTMLElement,
                             eventName: string,
                             customEventData: CustomEventInit = null) {
    if (typeof (<any> window).CustomEvent !== 'function') {
        import (/* webpackChunkName: "custom-event-polyfill" */ '../../polyfills/custom-event')
            .then(({ customEvent }) => {
                customEvent();
                element.dispatchEvent(new CustomEvent(eventName, customEventData));
            });

        return;
    }

    const event = new CustomEvent(eventName, customEventData);
    element.dispatchEvent(event);
}
