import { Component, componentService } from '@contorion/core';

componentService.register('.js-breadcrumbs', (element: Element) => {
    import (
        /* webpackChunkName: "breadcrumbs" */
        './components/breadcrumbs.component')
        .then(({ BreadcrumbsComponent }) => {
            <Component> BreadcrumbsComponent.loadByElement(element);
        });
});
