import { LoggerService } from '@contorion/core';

class LoggerServiceYves extends LoggerService {
    public async configNewrelic() {
        super.configNewrelic();

        //Adblocker Detection
        if(window?.contorion?.isAdBlockerActive === null) {
            return;
        }

        window.newrelic?.setCustomAttribute(
            'hasAdblocker',
            window.contorion.isAdBlockerActive,
            false
        );
    }
}

export const loggerServiceYves = new LoggerServiceYves();
