export function regexpFromString(pattern: string): RegExp {
    const match = /^\/(.*)\/([a-z]*)$/.exec(pattern);

    if (match === null) {
        throw new Error('Invalid RegExp string: ' + pattern);
    }

    if (match.length === 3) {
        return new RegExp(match[1], match[2]);
    }

    return new RegExp(match[1]);
}
