import { Component, componentService } from '@contorion/core';

componentService.register('.js-scroll-up-button', (element: Element) => {
    import (
        /* webpackChunkName: "scroll-up" */
        './components/scroll-up.component')
        .then(({ ScrollUpComponent }) => {
            <Component> ScrollUpComponent.loadByElement(element);
        });
});
