import { Component, componentService } from '@contorion/core';

componentService.register('.js-switch', (element: Element) => {
    import (
        /* webpackChunkName: "switch" */
        './components/switch.component')
        .then(({ SwitchComponent }) => {
            <Component> SwitchComponent.loadByElement(element);
        });
});

componentService.register('.js-facet-switch', (element: Element) => {
    import (
        /* webpackChunkName: "facet-switch" */
        './components/facet-switch.component')
        .then(({ FacetSwitchComponent }) => {
            <Component> FacetSwitchComponent.loadByElement(element);
        });
});

componentService.register('.js-redirect-switch', (element: Element) => {
    import (
        /* webpackChunkName: "redirect-switch" */
        './components/redirect-switch.component')
        .then(({ RedirectSwitchComponent }) => {
            <Component> RedirectSwitchComponent.loadByElement(element);
        });
});

componentService.register('.js-product-list-layout-switch', (element: Element) => {
    import (
        /* webpackChunkName: "product-list-layout-switch" */
        './components/product-list-layout-switch.component')
        .then(({ ProductListLayoutSwitchComponent }) => {
            <Component> ProductListLayoutSwitchComponent.loadByElement(element);
        });
});

componentService.register('.js-product-filters-theme-switch', (element: Element) => {
    import (
        /* webpackChunkName: "product-filters-theme-switch" */
        './components/product-filters-theme-switch.component')
        .then(({ ProductFiltersThemeSwitchComponent }) => {
            <Component> ProductFiltersThemeSwitchComponent.loadByElement(element);
        });
});
