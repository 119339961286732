import { Component, componentService } from '@contorion/core';

componentService.register('.js-sitewide-banner', (element: Element) => {
    import (
        /* webpackChunkName: "sitewide-banner" */
        './components/sitewide-banner.component')
        .then(({SitewideBannerComponent}) => {
            <Component> SitewideBannerComponent.loadByElement(element);
        });
});
