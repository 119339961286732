import { Component, componentService } from '@contorion/core';

componentService.register('.js-sticky-element', (element: Element) => {
    import (
        /* webpackChunkName: "sticky-element" */
        './components/sticky-element.component')
        .then(({StickyElementComponent}) => {
            <Component> StickyElementComponent.loadByElement(element);
        });
});
