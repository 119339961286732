import { Component, componentService } from '@contorion/core';

componentService.register('.js-view-in-iframe', (element: Element) => {
    import (
        /* webpackChunkName: "view-in-iframe" */
        './components/view-in-iframe.component')
        .then(({ ViewInIframeComponent }) => {
            <Component> ViewInIframeComponent.loadByElement(element);
        });
});
