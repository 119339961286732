import { elements as $$ } from '../helpers';

const SELECTOR_VARIANT_BUTTON: string = 'js-config-variant-button';

class FormSubmitButtonConditions {
    form: HTMLFormElement;
    buttons: Array<HTMLButtonElement>;
    conditions: Map<string, boolean>;

    constructor(form: HTMLFormElement,
                buttons: Array<HTMLButtonElement>,
                conditions: Map<string, boolean>) {
        this.form = form;
        this.buttons = buttons;
        this.conditions = conditions;
    }
}

const CLASS_LOADING = 'loading';
const SELECTOR_BUTTON = 'button';
const SELECTOR_FORM = 'form';

class FormSubmitButtonService {
    protected data: Array<FormSubmitButtonConditions> = [];
    protected buttons: Array<HTMLButtonElement>;

    public registerForm(form: HTMLFormElement) {
        this.data.push(new FormSubmitButtonConditions(
            form, this.getButtons(form), new Map<string, boolean>()));
    }

    public setCondition(element: HTMLElement, key: string, value: boolean) {
        const parentForm = this.findParentFormElement(element);
        const condition = this.findCondition(parentForm);
        if (condition) {
            condition.conditions.set(key, value);
            const loading = Array.from(condition.conditions.values()).some((val) => val);
            this.setLoadingTo(condition.buttons, loading);
            this.setDisableAttribute(element, loading);
        }
    }

    public setDisableAttribute(element: HTMLElement, value: boolean) {
        const form = this.findParentFormElement(element);
        const condition = this.findCondition(form);
        condition.buttons.forEach((button) => button.disabled = value);
    }
    private findParentFormElement(element: HTMLElement) {
        return element.closest(SELECTOR_FORM);
    }

    private findCondition(element: HTMLFormElement) {
        return this.data.find((data ) => data.form.isSameNode(element));
    }

    private getButtons(form: HTMLFormElement): Array<HTMLButtonElement> {
        return $$(SELECTOR_BUTTON, form)
            .filter((button: HTMLButtonElement) => this.isNotConfigVariantSelectionButton(button))
            .map((element: HTMLButtonElement) => element);
    }

    private setLoadingTo(buttons: Array<HTMLButtonElement>,value: boolean) {
        buttons.forEach((button ) => {
            if (value) {
                button.classList.add(CLASS_LOADING);
            } else {
                button.classList.remove(CLASS_LOADING);
            }
        });
    }

    private isNotConfigVariantSelectionButton(button: HTMLButtonElement) {
        return !button.classList.contains(SELECTOR_VARIANT_BUTTON);
    }
}

export const formSubmitButtonsService = new FormSubmitButtonService();
