import { closest, elements as $$ } from '../helpers';

const CLASS_COMPONENT_LAZY_LOAD: string = 'js-component-lazy-load';
const SELECTOR_COMPONENT_LAZY_LOAD: string = '.js-component-lazy-load';

/**
 * TODO: clear RAM
 * TODO: bind element to component instance
 */
class ComponentService {
    protected selectorCallbackMap: Map<string, any> = new Map();
    protected intersectionObserver: IntersectionObserver;
    protected intersectionObserverOptions: IntersectionObserverInit = {
        threshold: 0.1
    };

    protected get lazyLoadContainers(): Array<Element> {
        return $$(SELECTOR_COMPONENT_LAZY_LOAD, document.body);
    }

    public initialLoad(): void {
        this.intersectionObserver = new IntersectionObserver(this.onVisible.bind(this),
            this.intersectionObserverOptions);

        this.lazyLoadByContainer();
        this.load();
    }

    public register(selector: string, callback: any): void {
        this.selectorCallbackMap.set(selector, callback);
    }

    public load(parent: Element = document.body, isLazy: boolean = false) {
        this.selectorCallbackMap.forEach((callback, selector) => {
            const elements: Array<Element> = $$(selector, parent);

            elements.forEach((element) => {
                if (isLazy === false
                    && closest(element, SELECTOR_COMPONENT_LAZY_LOAD) !== null) {
                    this.lazyLoadByContainer();

                    return;
                }

                callback(element);
            });
        });
    }

    public lazyLoadByContainer(): void {
        this.lazyLoadContainers.forEach((containerElement: Element) => {
            this.intersectionObserver.observe(containerElement);
        });
    }

    protected onVisible(entries: Array<IntersectionObserverEntry>): void {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.intersectionRatio > 0) {
                const targetElement: Element = entry.target;

                this.load(targetElement, true);
                this.intersectionObserver.unobserve(targetElement);

                targetElement.classList.remove(CLASS_COMPONENT_LAZY_LOAD);
            }
        });
    }
}

export const componentService = new ComponentService();
