import { Component, componentService } from '@contorion/core';

componentService.register('.js-free-shipping-box', (element: Element) => {
    import (
        /* webpackChunkName: "free-shipping-box" */
        './components/free-shipping-box.component')
        .then(({ FreeShippingBoxComponent }) => {
            <Component> FreeShippingBoxComponent.loadByElement(element);
        });
});
