import { Component, componentService } from '@contorion/core';

componentService.register('.js-content-loader', (element: Element) => {
    import (/* webpackChunkName: "content-loader" */ './components/content-loader.component')
        .then(({ ContentLoaderComponent }) => {
            <Component> ContentLoaderComponent.loadByElement(element);
        });
});

componentService.register('.js-content-synchronizer', (element: Element) => {
    import (/* webpackChunkName: "content-synchronizer" */ './components/content-synchronizer.component')
        .then(({ ContentSynchronizerComponent }) => {
            <Component> ContentSynchronizerComponent.loadByElement(element);
        });
});
