export class Component {
    public static loadByElement(element: Element, componentSelector?: string) {
        return new this(element, componentSelector);
    }

    constructor(protected element: Element, protected componentSelector?: string ) {
    }

    public getSelector() {
        return this.componentSelector;
    }

    public getElement(): Element {
        return this.element;
    }
}
