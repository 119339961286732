import { Component, componentService } from '@contorion/core';

componentService.register('.js-activator', (element: Element) => {
    import (
        /* webpackChunkName: "activator" */
        './components/activator.component')
        .then(({ ActivatorComponent }) => {
            <Component> ActivatorComponent.loadByElement(element, '.js-activator');
        });
});

componentService.register('.js-class-toggler', (element: Element) => {
    import (
        /* webpackChunkName: "class-toggler" */
        './components/class-toggler.component')
        .then(({ ClassTogglerComponent }) => {
            <Component> ClassTogglerComponent.loadByElement(element);
        });
});

componentService.register('.js-class-remover', (element: Element) => {
    import (
        /* webpackChunkName: "class-remover" */
        './components/class-remover.component')
        .then(({ ClassRemoverComponent }) => {
            <Component> ClassRemoverComponent.loadByElement(element);
        });
});
