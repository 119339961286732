import { Component, componentService } from '@contorion/core';

componentService.register('.js-cookie-hint', (element: Element) => {
    import (
        /* webpackChunkName: "cookie-hint" */
        './components/cookie-hint.component')
        .then(({ CookieHintComponent }) => {
            <Component> CookieHintComponent.loadByElement(element);
        });
});
