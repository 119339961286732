import { Component, componentService } from '@contorion/core';

componentService.register('.js-ga4-purchase-event', (element: Element) => {
    import (
        /* webpackChunkName: "ga4-purchase-event" */
        './components/Ga4PurchaseEvent.component')
        .then(({ Ga4PurchaseEventComponent }) => {
            <Component> Ga4PurchaseEventComponent.loadByElement(element);
        });
});

componentService.register('.js-ga4-begin-checkout-event', (element: Element) => {
    import (
        /* webpackChunkName: "ga4-begin-checkout-event" */
        './components/Ga4BeginCheckoutEvent.component')
        .then(({ Ga4BeginCheckoutEventComponent }) => {
            <Component> Ga4BeginCheckoutEventComponent.loadByElement(element);
        });
});

componentService.register('.js-ga4-view-item-event', (element: Element) => {
    import (
        /* webpackChunkName: "ga4-view-item-event" */
        './components/Ga4ViewItemEvent.component')
        .then(({ Ga4ViewItemEvent }) => {
            <Component> Ga4ViewItemEvent.loadByElement(element);
        });
});

componentService.register('.js-ga4-view-cart-event', (element: Element) => {
    import (
        /* webpackChunkName: "ga4-view-cart-event" */
        './components/Ga4ViewCartEvent.component')
        .then(({ Ga4ViewCartEvent }) => {
            <Component> Ga4ViewCartEvent.loadByElement(element);
        });
});
