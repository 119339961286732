import { Component, componentService } from '@contorion/core';

componentService.register('.js-sticky-footer', (element: Element) => {
    import (
        /* webpackChunkName: "sticky-footer" */
        './components/sticky-footer.component')
        .then(({ StickyFooterComponent }) => {
            <Component> StickyFooterComponent.loadByElement(element);
        });
});
