interface IMatchesSelectorElement extends Element {
    matchesSelector(selectors: string): boolean;
    mozMatchesSelector(selectors: string): boolean;
    msMatchesSelector(selectors: string): boolean;
    webkitMatchesSelector(selectors: string): boolean;
    oMatchesSelector(selectors: string): boolean;
}

export function matches(el: IMatchesSelectorElement | Node, selector: string): boolean {
    const matchesSelectorElement = <IMatchesSelectorElement> el;

    if (!matchesSelectorElement) {
        return false;
    }

    const m = matchesSelectorElement.matches ||
        matchesSelectorElement.matchesSelector ||
        matchesSelectorElement.msMatchesSelector ||
        matchesSelectorElement.mozMatchesSelector ||
        matchesSelectorElement.webkitMatchesSelector ||
        matchesSelectorElement.oMatchesSelector;

    if (!m) {
        return false;
    }

    return m.call(el, selector);
}
