import * as core from '@contorion/core';
import './modules';
import { requestMiddleware as vivianRequestMiddleware } from './modules/vivian/middlewares/request.middleware';
import { vivianService } from './modules/vivian/services/vivian.service';
import {
    tabNabbService,
    serviceWorkerService,
} from '@contorion/core/services';
import { loggerServiceYves } from './modules/logger/logger-yves.service';
import {dyRecommendationService} from "@contorion/core";

core.requestService.addRequestMiddleware(vivianRequestMiddleware);

export default function app() {
    core.componentService.initialLoad();
    core.trackingService.removeFromHistory();
    (<any> window).variationService = core.variationService;
    (<any> window).vivianService = vivianService;
    (<any> window).contorion = window.contorion || {};
    (<any> window).contorion.dyRecommendationService = dyRecommendationService;
    loggerServiceYves.configNewrelic();
    serviceWorkerService.unregisterServiceWorkers();
    tabNabbService.addPreventiveAttributesToUrls();
    window.dispatchEvent(new Event('contorion-api-load'));
}
