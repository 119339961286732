import { Component, componentService } from '@contorion/core';

componentService.register('.js-ajax-widget', (element: Element) => {
    import (/* webpackChunkName: "ajax-widget" */ './components/ajax-widget.component')
        .then(({ AjaxWidgetComponent }) => {
            <Component> AjaxWidgetComponent.loadByElement(element);
        });
});

componentService.register('.js-ajax-on-click-widget', (element: Element) => {
    import (/* webpackChunkName: "ajax-on-click-widget" */
        './components/ajax-on-click-widget.component')
        .then(({ AjaxOnClickWidgetComponent }) => {
            <Component> AjaxOnClickWidgetComponent.loadByElement(element);
        });
});
