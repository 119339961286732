import { BaseValidation } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';
import { PASSWORD_PATTERN } from './patterns';

const ERROR_TYPE: string = 'password';

export class PasswordValidation extends BaseValidation {
    protected static requiresValidation(element: FormElementComponent): boolean {
        return (<HTMLInputElement> element.getElement()).dataset.validatePassword !== undefined;
    }

    protected static create(element: FormElementComponent): PasswordValidation {
        return new PasswordValidation(element);
    }

    protected isValid(): boolean {
        return this.isEmpty() || PASSWORD_PATTERN.test(this.element.value);
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }
}
