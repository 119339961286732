import { BaseValidation } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';
import { CHAR_PATTERN } from './patterns';

const ERROR_TYPE: string = 'address';

export class AddressValidation extends BaseValidation {
    protected static requiresValidation(element: FormElementComponent): boolean {
        return (<HTMLElement> element.getElement())
            .dataset.validationAddress !== undefined;
    }

    protected static create(element: FormElementComponent): AddressValidation {
        return new AddressValidation(element);
    }

    protected isValid(): boolean {
        return this.isEmpty() || CHAR_PATTERN.test(this.element.value);
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }
}
