import { Component, componentService } from '@contorion/core';

componentService.register('.js-progress', (element: Element) => {
    import (
        /* webpackChunkName: "progress" */
        './components/progress.component')
        .then(({ ProgressComponent }) => {
            <Component> ProgressComponent.loadByElement(element);
        });
});
