import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-recaptcha', (element: Element) => {
    import (
        /* webpackChunkName: "recaptcha" */
        './components/recaptcha.component')
        .then(({ RecaptchaComponent }) => {
            <Component> RecaptchaComponent.loadByElement(element);
        });
});

componentService.register('.js-captcha-panel', (element: Element) => {
    import (
        /* webpackChunkName: "captcha-panel" */
        './components/captcha-panel.component')
        .then(({ CaptchaPanelComponent }) => {
            <Component> CaptchaPanelComponent.loadByElement(element);
        });
});

services.register('recaptcha-service', () => {
    return import (
        /* webpackChunkName: "recaptcha-service" */
        './services/recaptcha.service')
        .then(({ recaptchaService }) => {
            return recaptchaService;
        });
});
