import { FormComponent } from './form.component';
import { FormElementComponent } from './form-element.component';
import { closest, element as $, elements as $$ } from '../index';
import {parse, ParsedQs, stringify} from 'qs';

const CLASS_ACTIVE: string = 'active';
const CLASS_CURRENT: string = 'current';
const STEP_1: number = 1;
const SELECTOR_BACK_BUTTON: string = '.js-step-back';
const SELECTOR_STEP: string = '[data-step]';
const SELECTOR_TOGGLE_STEP: string = '[data-toggle-step]';

export class FormStepsComponent extends FormComponent {
    protected _params: any;

    protected get paramsFromUrl(): ParsedQs {
        return parse(window.location.search.substr(1));
    }

    protected get firstHiddenInvalidElement(): FormElementComponent {
        return this.elements.filter((formElement: FormElementComponent) => {
            return formElement.isValid() === false;
        }).pop();
    }

    protected get params(): any {
        return this._params || (this._params = this.paramsFromUrl);
    }

    protected set params(value: any) {
        const url: string = window.location.pathname + '?' + stringify(value);

        this._params = value;
        window.history.replaceState(value, '', url);
    }

    protected get steps(): number {
        return parseInt(this.element.getAttribute('data-steps'), 10);
    }

    protected get currentStep(): number {
        return Math.min(this.steps, this.params.step || STEP_1);
    }

    protected set currentStep(value: number) {
        const { params } = this;

        params.step = value;

        this.params = params;

        this.toggleSteps();
    }

    protected get backButtons(): Array<HTMLElement> {
        return $$<HTMLElement>(SELECTOR_BACK_BUTTON, this.element);
    }

    constructor(protected element: HTMLFormElement) {
        super(element);

        if (this.backButtons.length) {
            this.backButtons.map(
                (backButton) => backButton
                    .addEventListener('click', this.onBackButtonClick.bind(this)));
        }

        this.toggleSteps();
    }

    protected toggleSteps(): void {
        const activeSteps = $$(SELECTOR_STEP);
        const activeToggleSteps = $$(SELECTOR_TOGGLE_STEP);

        for (const element of [].concat(activeSteps, activeToggleSteps)) {
            const isToggle: boolean = element.dataset.toggleStep !== undefined;
            const stepValue: number = parseInt(
                isToggle ? element.dataset.toggleStep : element.dataset.step,
                10
            );

            if (
                (isToggle && stepValue === this.currentStep)
                || (!isToggle && stepValue < this.currentStep)
            ) {
                element.classList.add(CLASS_ACTIVE);
                element.classList.remove(CLASS_CURRENT);
            } else if (!isToggle && stepValue === this.currentStep) {
                element.classList.add(CLASS_ACTIVE);
                element.classList.add(CLASS_CURRENT);
            } else {
                element.classList.remove(CLASS_ACTIVE);
                element.classList.remove(CLASS_CURRENT);
            }
        }

        window.scrollTo(0, 0);
    }

    protected previousStep(): void {
        this.currentStep = Math.max(STEP_1, this.currentStep - 1);
    }

    protected nextStep(): void {
        this.currentStep = Math.min(this.steps, this.currentStep + 1);
    }

    protected elementIsValid(formElement: FormElementComponent): boolean {
        const element: HTMLInputElement = <HTMLInputElement> formElement.getElement();
        const isElementInStep: boolean =
            closest(element, `[data-toggle-step="${this.currentStep}"]`) !== null;

        if (!isElementInStep && this.hasNextStep()) {
            return true;
        }

        return super.elementIsValid(formElement);
    }

    protected hasNextStep(): boolean {
        return this.currentStep < this.steps;
    }

    protected onSubmit(e: Event): void {
        super.onSubmit(e);

        if (!this.isValid()) {
            this.goToInvalidStep();

            return;
        }

        if (this.hasNextStep()) {
            e.preventDefault();

            this.progress = false;

            this.nextStep();

            return;
        }
    }

    protected goToInvalidStep(): void {
        if (!this.firstHiddenInvalidElement) {
            return;
        }

        const invalidStepElement: HTMLElement
            = this.firstHiddenInvalidElement.getElement().closest(SELECTOR_TOGGLE_STEP);

        if (!invalidStepElement) {
            return;
        }

        const firstInvalidStep: number
            = parseInt(invalidStepElement.dataset.toggleStep, 10);

        if (this.currentStep <= firstInvalidStep) {
            return;
        }

        this.currentStep = firstInvalidStep;
    }

    protected onBackButtonClick(e: Event): void {
        e.preventDefault();

        this.previousStep();

        const target: HTMLElement = <HTMLElement> e.target || <HTMLElement> e.srcElement;
        const scrollToSelector: string = target.dataset.scrollTo;
        if (scrollToSelector !== undefined) {
            window.scrollTo(0, $(scrollToSelector).getBoundingClientRect().top);
        }
    }
}
