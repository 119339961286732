import { Component, componentService } from '@contorion/core';

componentService.register('.js-move-element', (element: Element) => {
    import (
        /* webpackChunkName: "move-element" */
        './components/move-element.component')
        .then(({MoveElementComponent}) => {
            <Component> MoveElementComponent.loadByElement(element);
        });
});
