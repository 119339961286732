import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-configurator-filters', (element: Element) => {
    import (/* webpackChunkName: "configurator-filters" */ './components/filters.component')
        .then(({ FiltersComponent }) => {
            <Component> FiltersComponent.loadByElement(element);
        });
});

componentService.register('.js-config-simples-buttons', (element: Element) => {
    import (/* webpackChunkName: "simple" */ './components/simple-filters.component')
        .then(({ SimpleFiltersComponent }) => {
            <Component> SimpleFiltersComponent.loadByElement(element);
        });
});

componentService.register('.js-configurator-products', (element: Element) => {
    import (/* webpackChunkName: "configurator-products" */ './components/products.component')
        .then(({ ProductsComponent }) => {
            <Component> ProductsComponent.loadByElement(element);
        });
});

componentService.register('.js-config-product', (element: Element) => {
    import (/* webpackChunkName: "product-config-item" */ './components/product-config-item.component')
        .then(({ ProductConfigItemComponent }) => {
            <Component> ProductConfigItemComponent.loadByElement(element);
        });
});

componentService.register('.js-products-sorting', (element: Element) => {
    import (/* webpackChunkName: "product-config-item" */ './components/products-sorting.component')
        .then(({ ProductsSortingComponent }) => {
            <Component> ProductsSortingComponent.loadByElement(element);
        });
});

services.register('configurator-service', () => {
    return import (/* webpackChunkName: "configurator-service" */ './services/configurator.service')
        .then(({ configuratorService }) => {
            return configuratorService;
        });
});
