import { matches } from './matches';

export function closest(el: Element, selector: string): Element {
    let parentNode: Element | Node = el;

    while (parentNode) {
        if (matches(parentNode, selector)) {
            return <Element> parentNode;
        }

        parentNode = parentNode.parentNode;
    }

    return null;
}
