import { Component, componentService } from '@contorion/core';

componentService.register('.js-component-observer', (element: Element) => {
    import (
        /* webpackChunkName: "component-observer" */
        './components/component-observer.component'
    )
        .then(({ ComponentObserverComponent }) => {
            <Component> ComponentObserverComponent.loadByElement(element);
        });
});
