import { Component, componentService } from '@contorion/core';

componentService.register('.js-form', (element: Element) => {
    import (
        /* webpackChunkName: "form" */
        './components/form.component')
        .then(({ FormComponent }) => {
            <Component> FormComponent.loadByElement(element);
        });
});

componentService.register('.js-quantity', (element: Element) => {
    import (
        /* webpackChunkName: "quantity" */
        './components/quantity.component')
        .then(({ QuantityComponent }) => {
            <Component> QuantityComponent.loadByElement(element);
        });
});

componentService.register('.js-password', (element: Element) => {
    import (
        /* webpackChunkName: "password" */
        './components/password.component')
        .then(({ PasswordComponent }) => {
            <Component> PasswordComponent.loadByElement(element);
        });
});

componentService.register('.js-related-selector', (element: Element) => {
    import (
        /* webpackChunkName: "related-selector" */
        './components/related-selector.component')
        .then(({ RelatedSelectorComponent }) => {
            <Component> RelatedSelectorComponent.loadByElement(element);
        });
});

componentService.register('.js-dependent-field', (element: Element) => {
    import (
        /* webpackChunkName: "dependent-field" */
        './components/dependent-field.component')
        .then(({ DependentFieldComponent }) => {
            <Component> DependentFieldComponent.loadByElement(element);
        });
});

componentService.register('.js-item-quantity-selector', (element: Element) => {
    import (
        /* webpackChunkName: "item-quantity-selector" */
        './components/item-quantity-selector.component')
        .then(({ ItemQuantitySelectorComponent }) => {
            <Component> ItemQuantitySelectorComponent.loadByElement(element);
        });
});

componentService.register('.js-form-value', (element: Element) => {
    import (
        /* webpackChunkName: "form-value" */
        './components/form-value.component')
        .then(({ FormValueComponent }) => {
            <Component> FormValueComponent.loadByElement(element);
        });
});

componentService.register('.js-dependent-disabled', (element: Element) => {
    import (
        /* webpackChunkName: "dependent-disabled" */
        './components/dependent-disabled.component')
        .then(({ DependentDisabledComponent }) => {
            <Component> DependentDisabledComponent.loadByElement(element);
        });
});

componentService.register('.js-public-institution-type', (element: Element) => {
    import (
        /* webpackChunkName: "public-institution-type" */
        './components/public-institution-type.component')
        .then(({ PublicInstitutionTypeComponent }) => {
            <Component> PublicInstitutionTypeComponent.loadByElement(element);
        });
});

componentService.register('.js-form-value-hint-toggler', (element: Element) => {
    import (
        /* webpackChunkName: "form-value-hint-toggler" */
        './components/form-value-hint-toggler.component')
        .then(({ FormValueHintToggler }) => {
            <Component> FormValueHintToggler.loadByElement(element);
        });
});

componentService.register('.js-show-value-toggler', (element: Element) => {
    import (
        /* webpackChunkName: "show-value-toggler" */
        './components/show-value-toggler.component')
        .then(({ ShowValueTogglerComponent }) => {
            <Component> ShowValueTogglerComponent.loadByElement(element);
        });
});

componentService.register('.js-date-input', (element: Element) => {
    import (
        /* webpackChunkName: "date-input" */
        './components/date-input.component')
        .then(({ DateInputComponent }) => {
            <Component> DateInputComponent.loadByElement(element);
        });
});

componentService.register('.js-form-radio-group-validator', (element: Element) => {
    import (
        /* webpackChunkName: "form-radio-group-validator" */
        './components/form-radio-group-validator.component')
        .then(({ FormRadioGroupValidatorComponent }) => {
            <Component> FormRadioGroupValidatorComponent.loadByElement(element);
        });
});
