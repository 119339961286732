import { Component, componentService } from '@contorion/core';

componentService.register('.js-slider', (element: Element) => {
    import (/* webpackChunkName: "slider" */ './components/slider.component')
        .then(({ SliderComponent }) => {
            <Component> SliderComponent.loadByElement(element);
        });
});

componentService.register('.js-loop-slider', (element: Element) => {
    import (/* webpackChunkName: "loop-slider" */ './components/loop-slider.component')
        .then(({ LoopSliderComponent }) => {
            <Component> LoopSliderComponent.loadByElement(element);
        });
});
