import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-lazy-load-script', (element: Element) => {
    import (
        /* webpackChunkName: "lazy-load-script" */
        './components/lazy-load-script.component')
        .then(({ LazyLoadScriptComponent }) => {
            <Component> LazyLoadScriptComponent.loadByElement(element);
        });
});

services.register('lazy-load-service', () => {
    return import (
        /* webpackChunkName: "lazy-load-service" */
        './services/lazy-load.service'
    )
        .then(({ lazyLoadService }) => {
            return lazyLoadService;
        });
});
