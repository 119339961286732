class Services {
    protected services: Map<string, any> = new Map();

    register(name: string, loader: Function): void {
        this.services.set(name, loader);
    }

    async get(name: string): Promise<any> {
        const loader: any = this.services.get(name);
        const service: any = await loader();

        return service;
    }
}

export const services = new Services();
