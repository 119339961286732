import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-payone-credit-card', (element: Element) => {
    import (/* webpackChunkName: "payone-credit-card" */ './components/payone.component')
        .then(({ PayoneComponent }) => {
            <Component> PayoneComponent.loadByElement(element);
        });
});

services.register('credit-card-check-count', () => {
    return import (
        /* webpackChunkName: "payone-credit-card-count-service" */
        './services/payone-credit-card-check-count.service')
        .then(({ payoneCreditCardCheckService }) => {
            return payoneCreditCardCheckService;
        });
});
