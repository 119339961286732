import { Component, componentService } from '@contorion/core';

componentService.register('.js-disabled-indicator', (element: Element) => {
    import (
        /* webpackChunkName: "disabled-indicator" */
        './components/disabled-indicator.component')
        .then(({ DisabledIndicatorComponent }) => {
            <Component> DisabledIndicatorComponent.loadByElement(element);
        });
});
