import { IVivianService } from '../interfaces/vivian-service.interface';
import { consentService, requestService } from '@contorion/core';

const EVENT_TRACKING_URL: string = '/vivian/event';
const RESOLUTION_TRACKING_URL: string = '/vivian/add-resolution';
const DEFAULT_EVENT_TYPE: string = 'add-event';

class VivianService implements IVivianService {

    private requestIdYves: string;

    constructor() {
        this.refreshRequestIdYves(window.contorion.requestIdYves);
    }

    // each page render will get one unique id
    // update this id if filter changes for SRP/CRP page
    public refreshRequestIdYves(requestIdYves: string): void {
        this.requestIdYves = requestIdYves;
    }

    public track(params: string = '', eventType: string = DEFAULT_EVENT_TYPE): void {
        if (consentService.isAnalyticsGranted()) {
            requestService
                .get(`${EVENT_TRACKING_URL}/${eventType}?${params}&page_view_id=${this.requestIdYves}`);
        }
    }

    public trackResolution(params: string = ''): void {
        if (consentService.isAnalyticsGranted()) {
            requestService
                .get(`${RESOLUTION_TRACKING_URL}?${params}&page_view_id=${this.requestIdYves}`);
        }
    }
}

export const vivianService = new VivianService();
