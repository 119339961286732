import { BaseValidation } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';
import { regexpFromString } from '../../helpers';

const ERROR_TYPE: string = 'restricted-zip-code';

export class RestrictedZipCodeValidation extends BaseValidation {
    protected static requiresValidation(element: FormElementComponent): boolean {
        return (<HTMLElement> element.getElement())
            .dataset.restrictedZipCodePattern?.length > 0;
    }

    protected static create(element: FormElementComponent): RestrictedZipCodeValidation {
        return new RestrictedZipCodeValidation(element);
    }

    protected isValid(): boolean {
        const restrictedZipCodeRegExp: RegExp =
            regexpFromString(this.restrictedZipCodePattern);

        return this.isEmpty() || !restrictedZipCodeRegExp.test(this.element.value);
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }

    protected get restrictedZipCodePattern(): string {
        return this.element.dataset.restrictedZipCodePattern;
    }
}
