export const hash = function(input: string): number {
    let output: number = 0;

    if (input.length === 0) {
        return output;
    }

    for (let i: number = 0; i < input.length; i++) {
        let char: number = input.charCodeAt(i);

        output = ((output << 5) - output) + char;
        output |= 0;
    }

    return output;
};
