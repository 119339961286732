import { IScreenSize, IScreenSizes } from '../interfaces/screen-size.interface';

const BREAKPOINT_SM = 361;
const BREAKPOINT_M = 480;
const BREAKPOINT_LG = 769;
const BREAKPOINT_XL = 960;
const BREAKPOINT_XXL = 1280;

export const SCREEN_XSMALL: IScreenSize = {
    maxWidth: BREAKPOINT_SM - 1,
    minWidth: 0,
};

export const SCREEN_SMALL: IScreenSize = {
    maxWidth: BREAKPOINT_M - 1,
    minWidth: BREAKPOINT_SM,
};

export const SCREEN_MEDIUM: IScreenSize =  {
    maxWidth: BREAKPOINT_LG - 1,
    minWidth: BREAKPOINT_M,
};

export const SCREEN_LARGE: IScreenSize =  {
    maxWidth: BREAKPOINT_XL - 1,
    minWidth: BREAKPOINT_LG,
};

export const SCREEN_XLARGE: IScreenSize =  {
    maxWidth: BREAKPOINT_XXL - 1,
    minWidth: BREAKPOINT_XL,
};

export const SCREEN_XXLARGE: IScreenSize =  {
    maxWidth: 100000000,
    minWidth: BREAKPOINT_XXL,
};

export const SCREEN_SIZES: IScreenSizes = {
    xxlarge: SCREEN_XXLARGE,
    xlarge: SCREEN_XLARGE,
    large: SCREEN_LARGE,
    medium: SCREEN_MEDIUM,
    small: SCREEN_SMALL,
    xsmall: SCREEN_XSMALL,
};
