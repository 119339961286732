import { elements as $$ } from '../helpers/elements';
import { loggerService, requestService, ValidatorService, formSubmitButtonsService } from '../services';
import { Component } from './component';
import { FormElementComponent, FormControlElementType } from './form-element.component';

const SELECTOR_BUTTONS: string = 'button';
const CLASS_PROGRESS: string = 'inProgress';
const CONFIG_FORM_ELEMENTS_OBSERVER: MutationObserverInit = {
    attributes: false,
    childList: true,
    subtree: true,
};

const ARRAY_INPUT_TAGS: Array<string> = ['INPUT', 'SELECT', 'CHECKBOX'];

export class FormComponent extends Component {
    protected validator: ValidatorService;
    protected formElementComponentMap: Map<string, FormElementComponent> =
        new Map<string, FormElementComponent>();
    private formElementsObserver: MutationObserver;

    protected get controlElements(): Array<FormControlElementType> {
        return Array.apply(null, this.element.elements)
            .filter((el: Element): boolean => {
                return (el instanceof HTMLInputElement) ||
                    (el instanceof HTMLSelectElement) ||
                    (el instanceof HTMLTextAreaElement);
            });
    }

    protected get elements(): Array<FormElementComponent> {
        return Array.from(this.formElementComponentMap.values());
    }

    protected get buttons(): Array<HTMLButtonElement> {
        return $$(SELECTOR_BUTTONS, this.element)
            .map((element: HTMLButtonElement) => element);
    }

    protected get progress(): boolean {
        return this.element.classList.contains(CLASS_PROGRESS);
    }

    protected set progress(value: boolean) {
        if (value) {
            this.element.classList.add(CLASS_PROGRESS);
        } else {
            this.element.classList.remove(CLASS_PROGRESS);
        }
        formSubmitButtonsService.setDisableAttribute(this.element, value);
    }

    protected get action(): string {
        return this.element.action;
    }

    protected get method(): string {
        return this.element.method;
    }

    protected get values(): FormData {
        const values: FormData = new FormData();

        for (const element of this.elements) {
            const formElement: HTMLInputElement = <HTMLInputElement> element.getElement();
            const { checked, name, type, value } = formElement;

            if ((type === 'radio' || type === 'checkbox') && !checked) {
                continue;
            }

            values.append(name, value);
        }

        return values;
    }

    constructor(protected element: HTMLFormElement) {
        super(element);

        this.setFormElementComponents(this.controlElements);

        this.formElementsObserver = new MutationObserver(this.onFormElementsMutation.bind(this));
        this.formElementsObserver.observe(this.element, CONFIG_FORM_ELEMENTS_OBSERVER);

        this.validator = new ValidatorService(this);
        formSubmitButtonsService.registerForm(this.element);
        this.bindEvents();
    }

    public getElements(): Array<FormElementComponent> {
        return this.elements;
    }

    protected onFormElementsMutation(mutationList: Array<MutationRecord>): void {
        mutationList
            .reduce((acc, mutation) => {
                if (mutation.type === 'childList' && mutation.addedNodes?.length > 0) {
                    acc.push(mutation.addedNodes);
                }

                return acc;
            }, [])
            .forEach((addedNodes) => {
                this.handleAddedFormElement(addedNodes);
            });
    }

    protected handleAddedFormElement(addedNodes: NodeList): void {
        addedNodes.forEach((node) => {
            if (node.nodeType !== Node.ELEMENT_NODE) {
                return;
            }

            if (ARRAY_INPUT_TAGS.indexOf((<HTMLElement> node).tagName) > -1) {
                this.setFormElementComponent(<FormControlElementType> node);
            }

            if (node.hasChildNodes()) {
                this.setFormElementComponents(
                    $$<FormControlElementType>(ARRAY_INPUT_TAGS.join(','), (<HTMLElement> node))
                );
            }
        });

        this.validator = new ValidatorService(this);
    }

    protected setFormElementComponent(element: FormControlElementType): void {
        if (!element.name) {
            return;
        }

        this.formElementComponentMap.set(
            `${element.id}-${element.name}`,
            new FormElementComponent(element)
        );
    }

    protected setFormElementComponents(elements: Array<FormControlElementType>): void {
        elements.forEach(this.setFormElementComponent.bind(this));
    }

    protected bindEvents(): void {
        this.element.addEventListener('submit', this.onSubmit.bind(this));
    }

    protected elementIsValid(element: FormElementComponent): boolean {
        return element.isValid();
    }

    protected reportValidity(): void {
        for (let element of this.elements) {
            if (!this.elementIsValid(element)) {
                element.reportValidity();
            }
        }
    }

    protected isValid(): boolean {
        let isValid: boolean = true;

        for (let element of this.elements) {
            if (!this.elementIsValid(element)) {
                isValid = false;
            }
        }

        return isValid;
    }

    protected async submitViaAjax(): Promise<Response> {
        const { action, values, method } = this;
        let response: Response;

        this.progress = true;

        switch (method.toLowerCase()) {
            case 'post':
                response = await requestService.post(action, values);
                break;
            case 'get':
                response = await  requestService.get(action);
                break;
            default:
                loggerService.info(
                    `Method "${method.toLowerCase()}" not implemented in submitViaAjax`
                );
                break;
        }

        this.progress = false;

        return response;
    }

    protected onSubmit(e: Event): void {
        if (!this.isValid()) {
            e.preventDefault();

            this.reportValidity();

            return;
        }

        this.progress = true;
    }
}
