import { Component, componentService } from '@contorion/core';

componentService.register('.js-goodie-config-simple', (element: Element) => {
    import (
        /* webpackChunkName: "goodie-simple-config" */
        './components/goodie-config-simple.component'
        )
        .then(({ GoodieConfigSimpleComponent }) => {
            <Component> GoodieConfigSimpleComponent.loadByElement(element);
        });
});
