import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-whf', (element: Element) => {
    import (
        /* webpackChunkName: "whf" */
        './components/whf.component')
        .then(({ WhfComponent }) => {
            <Component> WhfComponent.loadByElement(element);
        });
});

services.register('whf-service', () => {
    return import (
        /* webpackChunkName: "whf-service" */
        './services/whf.service'
        )
        .then(({ whfService }) => {
            return whfService;
        });
});
