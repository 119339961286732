import { AddressNotAllowedValidation } from './address-not-allowed.validation';
import { AddressValidation } from './address.validation';
import { ConfirmationValidation } from './confirmation.validation';
import { EmailValidation } from './email.validation';
import { HousenumberValidation } from './housenumber.validation';
import { MaxlengthValidation } from './maxlength.validation';
import { PasswordValidation } from './password.validation';
import { RestrictedZipCodeValidation } from './restricted-zip-code.validation';
import { VatIdValidation } from './vat-id.validation';
import { TaxIdValidation } from './tax-id.validation';

export const validations: any = {
    AddressNotAllowedValidation,
    AddressValidation,
    ConfirmationValidation,
    EmailValidation,
    HousenumberValidation,
    MaxlengthValidation,
    PasswordValidation,
    RestrictedZipCodeValidation,
    TaxIdValidation,
    VatIdValidation
};
