import { Component, componentService } from '@contorion/core';

componentService.register('img', (element: Element) => {
    import (
        /* webpackChunkName: "image" */
        './components/image.component')
        .then(({ ImageComponent }) => {
            <Component> ImageComponent.loadByElement(element);
        });
});

componentService.register('picture', (element: Element) => {
    import (
        /* webpackChunkName: "picture" */
        './components/picture.component')
        .then(({ PictureComponent }) => {
            <Component> PictureComponent.loadByElement(element);
        });
});
