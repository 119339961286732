export const cookies = {
    add(name: string, value: any, expiration: number = 0, path?: string): void {
        const expires: Date = new Date((new Date()).getTime() + expiration);
        const pathVariable: string = path ? `path=${path}` : '';

        document.cookie = `${name}=${value}; expires=${expires}; ${pathVariable}`;
    },

    remove(name: string): void {
        const expires: Date = new Date(0);

        document.cookie = `${name}=; expires=${expires}`;
    },

    get(cookieName: string): string {
        const name: string = cookieName + '=';
        const allCookies: Array<string> = document.cookie.split(';');

        for (let cookie of allCookies) {
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }

            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }

        return '';
    }
};
