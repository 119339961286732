export const prettifyMarkup = function(markupString: string): string {
    const container: HTMLElement = document.createElement('div');
    container.innerHTML = markupString.trim();

    return format(container, 0).innerHTML;
};

function format(element: HTMLElement, level: number) {

    const indentBefore: string = new Array(level++ + 1).join('  ');
    const indentAfter: string = new Array(level - 1).join('  ');
    let textNode: Node;

    for (let i = 0; i < element.children.length; i++) {

        textNode = document.createTextNode('\n' + indentBefore);
        element.insertBefore(textNode, element.children[i]);

        format(<HTMLElement> element.children[i], level);

        if (element.lastElementChild === element.children[i]) {
            textNode = document.createTextNode('\n' + indentAfter);
            element.appendChild(textNode);
        }
    }

    return element;
}
