export class ContentObserverService {
    protected observer: MutationObserver;

    protected config: MutationObserverInit;

    constructor(config: MutationObserverInit = null) {
        this.config = config || {
            childList: true,
        };
    }

    public observe(element: Element, once: boolean = true): Promise<void> {
        if (!element) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            this.observer = new MutationObserver(() => {
                if (once) {
                    this.observer.disconnect();
                    this.observer = null;
                }

                resolve();
            });

            this.observer.observe(element, this.config);
        });
    }
}
