import { cookies, hash, services } from '@contorion/core';
import { stringify } from 'qs';
import { IVivianService } from '../../vivian/interfaces/vivian-service.interface';

const COOKIE_NAME_SESSION: string = 'sessionid';
const COOKIE_NAME_RESOLUTION: string = 'resolutionTrackingHash';
const COOKIE_EXPIRY_RESOLUTION: number = 604800000;
const COOKIE_PATH_RESOLUTION: string = '/';

class ResolutionTrackerComponent {
    protected vivianService: IVivianService;

    protected get trackingValue(): string {
        return stringify(this.getResolution());
    }

    protected get currentTrackingHash(): string {
        const resolutionString: string =
            `${this.getResolution().resolutionHeight}x${this.getResolution().resolutionWidth}`;

        return hash(`${resolutionString} ${cookies.get(COOKIE_NAME_SESSION)}`).toString(10);
    }

    protected get cookieTrackingHash(): string {
        return cookies.get(COOKIE_NAME_RESOLUTION);
    }

    constructor() {
        if (!this.shouldTriggerVivianTracking()) {
            return;
        }

        this.init();
    }

    protected async init(): Promise<void> {
        this.updateResolutionCookie();
        this.vivianService = await services.get('vivian-service');
        this.vivianService.trackResolution(this.trackingValue);
    }

    protected getResolution(): any {
        // any change to key names should be also made in VivianTrackingController.php class
        return {
            resolutionHeight: window.innerHeight,
            resolutionWidth: window.innerWidth,
        };
    }

    protected updateResolutionCookie(): void {
        cookies.remove(COOKIE_NAME_RESOLUTION);
        cookies.add(
            COOKIE_NAME_RESOLUTION,
            this.currentTrackingHash,
            COOKIE_EXPIRY_RESOLUTION,
            COOKIE_PATH_RESOLUTION
        );
    }

    protected shouldTriggerVivianTracking(): boolean {
        return this.currentTrackingHash !== this.cookieTrackingHash;
    }
}

export  const resolutionTrackerComponent: ResolutionTrackerComponent =
    new ResolutionTrackerComponent();
