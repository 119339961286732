import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-video', (element: Element) => {
    import (/* webpackChunkName: "video" */ './components/video.component')
        .then(({ VideoComponent }) => {
            <Component> VideoComponent.loadByElement(element);
        });
});

componentService.register('.js-inline-video', (element: Element) => {
    import (/* webpackChunkName: "inline-video" */ './components/inline-video.component')
        .then(({ InlineVideoComponent }) => {
            <Component> InlineVideoComponent.loadByElement(element);
        });
});

componentService.register('.js-demoup-thumbnail', (element: Element) => {
    import (/* webpackChunkName: "demoup-thumbnail" */ './components/demoup-thumbnail.component')
        .then(({ DemoupThumbnailComponent }) => {
            <Component> DemoupThumbnailComponent.loadByElement(element);
        });
});

componentService.register('.js-demoup-indicator', (element: Element) => {
    import (/* webpackChunkName: "demoup-indicator" */ './components/demoup-indicator.component')
        .then(({ DemoupIndicatorComponent }) => {
            <Component> DemoupIndicatorComponent.loadByElement(element);
        });
});

services.register('video-service', () => {
    return import (
        /* webpackChunkName: "video-service" */
        './services/video.service')
        .then(({ videoService }) => {
            return videoService;
        });
});
