import { BaseValidation } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';

const ERROR_TYPE: string = 'tax-id';
const TAX_ID_LENGTH: number = 16;
const REGEXP_LASTNAME: string = '[A-Za-z]{3}';
const REGEXP_FIRSTNAME: string = '[A-Za-z]{3}';
const REGEXP_BIRTHDATE_YEAR: string = '[0-9LlMmNnPpQqRrSsTtUuVv]{2}';
const REGEXP_BIRTHDATE_MONTH: string = '[AaBbCcDdEeHhLlMmPpRrSsTt]{1}';
const REGEXP_BIRTHDATE_DAY_GENDER_PART_1: string = '[0-7LlMmNnPpQqRrSsTtUuVv]{1}';
const REGEXP_BIRTHDATE_DAY_GENDER_PART_2: string = '[0-9LlMmNnPpQqRrSsTtUuVv]{1}';
const REGEXP_BIRTH_TOWN_PART_1: string = '[A-Za-z]{1}';
const REGEXP_BIRTH_TOWN_PART_2: string = '[0-9LlMmNnPpQqRrSsTtUuVv]{3}';
const REGEXP_STRING_FOR_CIN: string = '[A-Za-z]{1}';

const REGEXP =  new RegExp('^'
    + REGEXP_LASTNAME
    + REGEXP_FIRSTNAME
    + REGEXP_BIRTHDATE_YEAR
    + REGEXP_BIRTHDATE_MONTH
    + REGEXP_BIRTHDATE_DAY_GENDER_PART_1
    + REGEXP_BIRTHDATE_DAY_GENDER_PART_2
    + REGEXP_BIRTH_TOWN_PART_1
    + REGEXP_BIRTH_TOWN_PART_2
    + REGEXP_STRING_FOR_CIN
    + '$');

export class TaxIdValidation extends  BaseValidation {
    private static oddCharsMap: Map<string, number> = new Map();
    private static evenCharsMap: Map<string, number> = new Map();
    private static modsMap: Map<number, string> = new Map();

    protected static requiresValidation(element: FormElementComponent): boolean {

        return (<HTMLElement> element.getElement())
            .dataset.validationTaxId !== undefined;
    }

    protected static create(element: FormElementComponent): TaxIdValidation {
        TaxIdValidation.setEvenCharsMap();
        TaxIdValidation.setOddCharsMap();
        TaxIdValidation.setModsCharsMap();

        return new TaxIdValidation(element);
    }

    private static setOddCharsMap() {
        if (TaxIdValidation.oddCharsMap.size > 0) {
            return;
        }
        TaxIdValidation.oddCharsMap.set('0', 1);
        TaxIdValidation.oddCharsMap.set('1', 0);
        TaxIdValidation.oddCharsMap.set('2', 5);
        TaxIdValidation.oddCharsMap.set('3', 7);
        TaxIdValidation.oddCharsMap.set('4', 9);
        TaxIdValidation.oddCharsMap.set('5', 13);
        TaxIdValidation.oddCharsMap.set('6', 15);
        TaxIdValidation.oddCharsMap.set('7', 17);
        TaxIdValidation.oddCharsMap.set('8', 19);
        TaxIdValidation.oddCharsMap.set('9', 21);
        TaxIdValidation.oddCharsMap.set('A', 1);
        TaxIdValidation.oddCharsMap.set('B', 0);
        TaxIdValidation.oddCharsMap.set('C', 5);
        TaxIdValidation.oddCharsMap.set('D', 7);
        TaxIdValidation.oddCharsMap.set('E', 9);
        TaxIdValidation.oddCharsMap.set('F', 13);
        TaxIdValidation.oddCharsMap.set('G', 15);
        TaxIdValidation.oddCharsMap.set('H', 17);
        TaxIdValidation.oddCharsMap.set('I', 19);
        TaxIdValidation.oddCharsMap.set('J', 21);
        TaxIdValidation.oddCharsMap.set('K', 2);
        TaxIdValidation.oddCharsMap.set('L', 4);
        TaxIdValidation.oddCharsMap.set('M', 18);
        TaxIdValidation.oddCharsMap.set('N', 20);
        TaxIdValidation.oddCharsMap.set('O', 11);
        TaxIdValidation.oddCharsMap.set('P', 3);
        TaxIdValidation.oddCharsMap.set('Q', 6);
        TaxIdValidation.oddCharsMap.set('R', 8);
        TaxIdValidation.oddCharsMap.set('S', 12);
        TaxIdValidation.oddCharsMap.set('T', 14);
        TaxIdValidation.oddCharsMap.set('U', 16);
        TaxIdValidation.oddCharsMap.set('V', 10);
        TaxIdValidation.oddCharsMap.set('W', 22);
        TaxIdValidation.oddCharsMap.set('X', 25);
        TaxIdValidation.oddCharsMap.set('Y', 24);
        TaxIdValidation.oddCharsMap.set('Z', 23);
    }

    private static setEvenCharsMap() {
        if (TaxIdValidation.oddCharsMap.size > 0) {
            return;
        }
        TaxIdValidation.evenCharsMap.set('0', 0);
        TaxIdValidation.evenCharsMap.set('1', 1);
        TaxIdValidation.evenCharsMap.set('2', 2);
        TaxIdValidation.evenCharsMap.set('3', 3);
        TaxIdValidation.evenCharsMap.set('4', 4);
        TaxIdValidation.evenCharsMap.set('5', 5);
        TaxIdValidation.evenCharsMap.set('6', 6);
        TaxIdValidation.evenCharsMap.set('7', 7);
        TaxIdValidation.evenCharsMap.set('8', 8);
        TaxIdValidation.evenCharsMap.set('9', 9);
        TaxIdValidation.evenCharsMap.set('A', 0);
        TaxIdValidation.evenCharsMap.set('B', 1);
        TaxIdValidation.evenCharsMap.set('C', 2);
        TaxIdValidation.evenCharsMap.set('D', 3);
        TaxIdValidation.evenCharsMap.set('E', 4);
        TaxIdValidation.evenCharsMap.set('F', 5);
        TaxIdValidation.evenCharsMap.set('G', 6);
        TaxIdValidation.evenCharsMap.set('H', 7);
        TaxIdValidation.evenCharsMap.set('I', 8);
        TaxIdValidation.evenCharsMap.set('J', 9);
        TaxIdValidation.evenCharsMap.set('K', 10);
        TaxIdValidation.evenCharsMap.set('L', 11);
        TaxIdValidation.evenCharsMap.set('M', 12);
        TaxIdValidation.evenCharsMap.set('N', 13);
        TaxIdValidation.evenCharsMap.set('O', 14);
        TaxIdValidation.evenCharsMap.set('P', 15);
        TaxIdValidation.evenCharsMap.set('Q', 16);
        TaxIdValidation.evenCharsMap.set('R', 17);
        TaxIdValidation.evenCharsMap.set('S', 18);
        TaxIdValidation.evenCharsMap.set('T', 19);
        TaxIdValidation.evenCharsMap.set('U', 20);
        TaxIdValidation.evenCharsMap.set('V', 21);
        TaxIdValidation.evenCharsMap.set('W', 22);
        TaxIdValidation.evenCharsMap.set('X', 23);
        TaxIdValidation.evenCharsMap.set('Y', 24);
        TaxIdValidation.evenCharsMap.set('Z', 25);
    }

    private static setModsCharsMap() {
        if (TaxIdValidation.modsMap.size > 0) {
            return;
        }
        TaxIdValidation.modsMap.set(0, 'A');
        TaxIdValidation.modsMap.set(1, 'B');
        TaxIdValidation.modsMap.set(2, 'C');
        TaxIdValidation.modsMap.set(3, 'D');
        TaxIdValidation.modsMap.set(4, 'E');
        TaxIdValidation.modsMap.set(5, 'F');
        TaxIdValidation.modsMap.set(6, 'G');
        TaxIdValidation.modsMap.set(7, 'H');
        TaxIdValidation.modsMap.set(8, 'I');
        TaxIdValidation.modsMap.set(9, 'J');
        TaxIdValidation.modsMap.set(10, 'K');
        TaxIdValidation.modsMap.set(11, 'L');
        TaxIdValidation.modsMap.set(12, 'M');
        TaxIdValidation.modsMap.set(13, 'N');
        TaxIdValidation.modsMap.set(14, 'O');
        TaxIdValidation.modsMap.set(15, 'P');
        TaxIdValidation.modsMap.set(16, 'Q');
        TaxIdValidation.modsMap.set(17, 'R');
        TaxIdValidation.modsMap.set(18, 'S');
        TaxIdValidation.modsMap.set(19, 'T');
        TaxIdValidation.modsMap.set(20, 'U');
        TaxIdValidation.modsMap.set(21, 'V');
        TaxIdValidation.modsMap.set(22, 'W');
        TaxIdValidation.modsMap.set(23, 'X');
        TaxIdValidation.modsMap.set(24, 'Y');
        TaxIdValidation.modsMap.set(25, 'Z');
    }

    protected isValid(): boolean {
        if (!TaxIdValidation.requiresValidation(this.formElement)) {
            return true;
        }

        return this.isEmpty()
            || (this.isLengthValid() && this.matchesRegex() && this.isChecksumValid());
    }

    protected isLengthValid(): boolean {
        return this.element.value.length === TAX_ID_LENGTH;
    }

    protected matchesRegex():boolean {
        return REGEXP.test(this.element.value);
    }

    protected isChecksumValid(): boolean {
        let checksum = 0;
        const value = this.element.value;

        for (let i = 0 ; i < TAX_ID_LENGTH - 1; i++) {
            if (((i + 1) % 2) > 0) {
                checksum += TaxIdValidation.oddCharsMap.get(value.charAt(i).toUpperCase());
            } else {
                checksum += TaxIdValidation.evenCharsMap.get(value.charAt(i).toUpperCase());
            }
        }

        return TaxIdValidation.modsMap.get(checksum % 26) === value.slice(-1).toUpperCase();
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }
}
