import {loggerService} from "./logger.service";

class DyStaticApiService {
    private callbackQueue : Array<any> = [];

    constructor() {
        window.addEventListener('dy-static-load', () => {
            this.executeQueue();
        });
    }
    public ready(callback: any) {
        this.callbackQueue.push(callback);
        this.executeQueue();
    }

    private executeQueue(): void {
        if (!window.DYO || !this.callbackQueue.length){
            return;
        }

        while (this.callbackQueue.length) {
            const callback: any = this.callbackQueue.pop();

            try {
                callback();
            }
            catch (e) {
                loggerService.error(e, 'DY static api service executeQueue')
            }
        }
    }
}

export const dyStaticApiService: DyStaticApiService = new DyStaticApiService();
