import { loggerService, SCREEN_SIZES } from '../../core';
import { IScreenSize } from '../interfaces';

export class Screen {
    protected get screenWidth(): number {
        return window.innerWidth;
    }

    public is(screenSizeName: string) :boolean {

        if (!this.isScreenSizeAvailable(screenSizeName)) {
            return false;
        }

        const sizeToCheck: IScreenSize = SCREEN_SIZES[screenSizeName];

        return (this.screenWidth >= sizeToCheck.minWidth) &&
                (this.screenWidth <= sizeToCheck.maxWidth);

    }

    public isMaximum(screenSizeName: string): boolean {
        if (!this.isScreenSizeAvailable(screenSizeName)) {
            return false;
        }

        const sizeToCheck: IScreenSize = SCREEN_SIZES[screenSizeName];

        return (this.screenWidth > sizeToCheck.minWidth || this.is(screenSizeName));
    }

    public isMinimum(screenSizeName: string): boolean {
        if (!this.isScreenSizeAvailable(screenSizeName)) {
            return false;
        }

        const sizeToCheck: IScreenSize = SCREEN_SIZES[screenSizeName];

        return (this.screenWidth < sizeToCheck.maxWidth || this.is(screenSizeName));
    }

    protected isScreenSizeAvailable(screenSizeName: string) : boolean {
        if (!SCREEN_SIZES.hasOwnProperty(screenSizeName)) {
            loggerService.error(`Screen size ${screenSizeName} is not available!`);

            return false;
        }

        return true;
    }
}

export const screen: Screen = new Screen();
