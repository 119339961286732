import { BaseValidation, TYPE_WARNING } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';
import { NUMBER_PATTERN } from './patterns';

const ERROR_TYPE: string = 'housenumber';

export class HousenumberValidation extends BaseValidation {
    protected static requiresValidation(element: FormElementComponent): boolean {
        return (<HTMLElement> element.getElement())
            .dataset.warningHousenumber !== undefined;
    }

    protected static create(element: FormElementComponent): HousenumberValidation {
        return new HousenumberValidation(element);
    }

    protected isValid(): boolean {
        return this.isEmpty() || NUMBER_PATTERN.test(this.element.value);
    }

    protected getType(): string {
        return TYPE_WARNING;
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }
}
