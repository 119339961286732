import { ILocalStorageSettings as Settings } from '../../core';

const STORAGE_KEY: string = 'settings';

export class LocalStorage {

    private _storage: Settings;

    private set storage(_storage: Settings) {
        this._storage = _storage;
        try {
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this._storage));
        } catch (error) {
            console &&
                console.log &&
                console.log('could not write to local storage: ' + error);
        }
    }

    private get storage(): Settings {
        try {
            return this._storage =
                <Settings> JSON.parse(window.localStorage.getItem(STORAGE_KEY)) || {};

        } catch (error) {
            console &&
                console.log &&
                console.log('could not read from local storage: ' + error);
        }

        return this._storage = {};
    }

    public setValue(property: string, value: any): void {
        if (typeof window.localStorage !== 'undefined') {
            let storage = this.storage;
            storage[property] = value;

            this.storage = storage;
        }
    }

    public getValue(property: string): any {
        return this.storage.hasOwnProperty(property) ? this.storage[property] : null;
    }

    public removeValue(property: string): void {
        if (this.storage.hasOwnProperty(property)) {
            let storage = this.storage;
            delete storage[property];
            this.storage = storage;
        }
    }
}

export const localStorageService: LocalStorage = new LocalStorage();
