export function jsonToFormData(json: any): FormData {
    const formData: FormData = new FormData();

    for (const key in json) {
        if (!json.hasOwnProperty(key)) {
            continue;
        }

        const value: any = json[key];

        append(formData, key, value);
    }

    return formData;
}

function append(formData: FormData, key: string, value: any): void {
    if (Array.isArray(value)) {
        for (const v of value) {
            append(formData, `${key}[]`, v);
        }

        return;
    }

    if (typeof value === 'object') {
        for (const vKey in value) {
            if (!value.hasOwnProperty(vKey)) {
                continue;
            }

            append(formData, `${key}[${vKey}]`, value[vKey]);
        }

        return;
    }

    formData.append(key, value);
}
