export const EASE_LINEAR = (t: number, b: number, c: number, d: number) => {
    return c * t / d + b;
};

export const EASE_IN_QUAD = (t: number, b: number, c: number, d: number) => {
    t /= d;

    return c * t * t + b;
};

export const EASE_OUT_QUAD = (t: number, b: number, c: number, d: number) => {
    t /= d;

    return -c * t * (t - 2) + b;
};

export const EASE_IN_OUT_QUAD = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) {
        return c / 2 * t * t + b;
    }

    t--;

    return -c / 2 * (t * (t - 2) - 1) + b;
};

export const EASE_IN_CUBIC = (t: number, b: number, c: number, d: number) => {
    t /= d;

    return c * t * t * t + b;
};

export const EASE_OUT_CUBIC = (t: number, b: number, c: number, d: number) => {
    t /= d;
    t--;

    return c * (t * t * t + 1) + b;
};

export const EASE_IN_OUT_CUBIC = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) {
        return c / 2 * t * t * t + b;
    }

    t -= 2;

    return c / 2 * (t * t * t + 2) + b;
};

export const EASE_IN_QUART = (t: number, b: number, c: number, d: number) => {
    t /= d;

    return c * t * t * t * t + b;
};

export const EASE_OUT_QUART = (t: number, b: number, c: number, d: number) => {
    t /= d;
    t--;

    return -c * (t * t * t * t - 1) + b;
};

export const EASE_IN_OUT_QUART = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) {
        return c / 2 * t * t * t * t + b;
    }

    t -= 2;

    return -c / 2 * (t * t * t * t - 2) + b;
};

export const EASE_IN_QUINT = (t: number, b: number, c: number, d: number) => {
    t /= d;

    return c * t * t * t * t * t + b;
};

export const EASE_OUT_QUINT = (t: number, b: number, c: number, d: number) => {
    t /= d;
    t--;

    return c * (t * t * t * t * t + 1) + b;
};

export const EASE_IN_OUT_QUINT = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) {
        return c / 2 * t * t * t * t * t + b;
    }

    t -= 2;

    return c / 2 * (t * t * t * t * t + 2) + b;
};

export const EASE_IN_SINE = (t: number, b: number, c: number, d: number) => {
    return -c * Math.cos(t / d * (Math.PI / 2)) + c + b;
};

export const EASE_OUT_SINE = (t: number, b: number, c: number, d: number) => {
    return c * Math.sin(t / d * (Math.PI / 2)) + b;
};

export const EASE_IN_OUT_SINE = (t: number, b: number, c: number, d: number) => {
    return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
};

export const EASE_IN_EXPO = (t: number, b: number, c: number, d: number) => {
    return c * Math.pow(2, 10 * (t / d - 1)) + b;
};

export const EASE_OUT_EXPO = (t: number, b: number, c: number, d: number) => {
    return c * (-Math.pow(2, -10 * t / d) + 1) + b;
};

export const EASE_IN_OUT_EXPO = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) {
        return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
    }

    t--;

    return c / 2 * (-Math.pow(2, -10 * t) + 2) + b;
};

export const EASE_IN_CIRC = (t: number, b: number, c: number, d: number) => {
    t /= d;

    return -c * (Math.sqrt(1 - t * t) - 1) + b;
};

export const EASE_OUT_CIRC = (t: number, b: number, c: number, d: number) => {
    t /= d;
    t--;

    return c * Math.sqrt(1 - t * t) + b;
};

export const EASE_IN_OUT_CIRC = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) {
        return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
    }

    t -= 2;

    return c / 2 * (Math.sqrt(1 - t * t) + 1) + b;
};
