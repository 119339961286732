import { Component, componentService, services } from '@contorion/core';

services.register('address-management', () => {
    return import (
        /* webpackChunkName: "address-management-service" */
        './services/address-management.service')
        .then(({ addressManagementService }) => {
            return addressManagementService;
        });
});

services.register('address-auto-complete', () => {
    return import (
        /* webpackChunkName: "address-auto-complete-service" */
        './services/address-auto-complete.service')
        .then(({ addressAutoCompleteService }) => {
            return addressAutoCompleteService;
        });
});

componentService.register('.js-address-management', (element: Element) => {
    import (
        /* webpackChunkName: "address-management" */
        './components/address-management.component'
        )
        .then(({ AddressManagementComponent }) => {
            <Component> AddressManagementComponent.loadByElement(element);
        });
});

componentService.register('.js-address-form', (element: Element) => {
    import (
        /* webpackChunkName: "address-form" */
        './components/address-form.component'
        )
        .then(({ AddressFormComponent }) => {
            <Component> AddressFormComponent.loadByElement(element);
        });
});

componentService.register('.js-address-auto-complete', (element: Element) => {
    import (
        /* webpackChunkName: "address-auto-complete" */
        './components/address-auto-complete.component'
        )
        .then(({ AddressAutoCompleteComponent }) => {
            <Component> AddressAutoCompleteComponent.loadByElement(element);
        });
});
