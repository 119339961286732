import { services } from '@contorion/core';

services.register('theme-toggler-service', () => {
    return import (
        /* webpackChunkName: "theme-toggler-service" */
        './services/theme-toggler.service')
        .then(({ themeToggleService }) => {
            return themeToggleService ;
        });
});
