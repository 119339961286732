import { elements as $$ } from '../helpers';

const SELECTOR_VULNERABLE_LINKS: string = 'a[target=_blank]';
const VALUE_REL: string = 'noopener noreferrer';

class TabNabbService {
    get targetBlankLinks(): Array<HTMLAnchorElement> {
        return $$<HTMLAnchorElement>(SELECTOR_VULNERABLE_LINKS);
    }

    public addPreventiveAttributesToUrls(): void {
        window.onload = () => {
            this.targetBlankLinks.forEach((anchor) => {
                anchor.setAttribute(
                    'rel',
                    `${anchor.getAttribute('rel') ?? ''} ${VALUE_REL}`
                );
            });
        };
    }
}

export const tabNabbService: TabNabbService = new TabNabbService();
