import { Component, componentService } from '@contorion/core';

componentService.register('.js-set-attributes', (element: Element) => {
    import (
        /* webpackChunkName: "set-attribute" */
        './components/set-attribute.component')
        .then(({ SetAttributeComponent }) => {
            <Component> SetAttributeComponent.loadByElement(element);
        });
});

componentService.register('.js-url-with-attributes', (element: Element) => {
    import (
        /* webpackChunkName: "url-with-attributes" */
        './components/set-url-attribute.component')
        .then(({ SetUrlAttributeComponent }) => {
            <Component> SetUrlAttributeComponent.loadByElement(element);
        });
});
