import { Component, componentService } from '@contorion/core';

componentService.register('.js-order-cancellation', (element: Element) => {
    import (
        /* webpackChunkName: "order-cancellation" */
        './components/order-cancellation.component')
        .then(({ OrderCancellationComponent }) => {
            <Component> OrderCancellationComponent.loadByElement(element);
        });
});

componentService.register('.js-login-modal-form', (element: Element) => {
    import (
        /* webpackChunkName: "login-modal-form" */
        './components/login-modal-form.component')
        .then(({ LoginModalFormComponent }) => {
            <Component> LoginModalFormComponent.loadByElement(element);
        });
});

componentService.register('.js-register-form', (element: Element) => {
    import (
        /* webpackChunkName: "register-form" */
        './components/register-form.component')
        .then(({ RegisterFormComponent }) => {
            <Component> RegisterFormComponent.loadByElement(element);
        });
});

componentService.register('.js-customer-announcement', (element: Element) => {
    import (
        /* webpackChunkName: "customer-announcement" */
        './components/customer-announcement.component')
        .then(({ CustomerAnnouncementComponent }) => {
            <Component> CustomerAnnouncementComponent.loadByElement(element);
        });
});

componentService.register('.js-payment-method-direct-debit-form', (element: Element) => {
    import (
        /* webpackChunkName: "payment-method-direct-debit-form" */
        './components/payment-method-direct-debit-form.component')
        .then(({ PaymentMethodDirectDebitFormComponent }) => {
            <Component> PaymentMethodDirectDebitFormComponent.loadByElement(element);
        });
});

componentService.register('.js-login-panel', (element: Element) => {
    import (
        /* webpackChunkName: "always-at-address" */
        './components/login-panel.component')
        .then(({ LoginPanelComponent }) => {
            <Component> LoginPanelComponent.loadByElement(element);
        });
});

componentService.register('.js-buyer-detail-form-it', (element: Element) => {
    import (
        /* webpackChunkName: "buyer-detail-form-it" */
        './components/buyer-detail-form-it.component')
        .then(({ BuyerDetailFormITComponent }) => {
            <Component> BuyerDetailFormITComponent.loadByElement(element);
        });
});

componentService.register('.js-order-history', (element: Element) => {
    import (
        /* webpackChunkName: "order-history" */
        './components/order-history.component')
        .then(({ OrderHistoryComponent }) => {
            <Component> OrderHistoryComponent.loadByElement(element);
        });
});

componentService.register('.js-company-size', (element: Element) => {
    import (
        /* webpackChunkName: "company-size" */
        './components/company-size.component')
        .then(({ CompanySizeComponent }) => {
            <Component> CompanySizeComponent.loadByElement(element);
        });
});
