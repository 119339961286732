import { stringify } from 'qs';

export const requestMiddleware: RequestMiddleware = (url: string): string => {
    const params: any = {
        vivian: window.contorion.vivian,
    };

    url += stringify(params);

    return url;
};
