export const KEY_BACKSPACE = 8;
export const KEY_ENTER = 13;
export const KEY_ESCAPE = 27;
export const KEY_ARROW_DOWN = 40;
export const KEY_ARROW_LEFT = 37;
export const KEY_ARROW_RIGHT = 39;
export const KEY_ARROW_UP = 38;
export const KEY_TAB = 9;
export const KEY_DEL = 46;
export const KEY_CTRL = 17;
export const KEY_ALT = 18;
export const KEY_CMD = 93;
export const CODE_BACKSPACE: string = 'Backspace';
