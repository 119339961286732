import { BaseValidation } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';
import { regexpFromString } from '../../helpers';

const ERROR_TYPE: string = 'address-not-allowed';

export class AddressNotAllowedValidation extends BaseValidation {
    protected static requiresValidation(element: FormElementComponent): boolean {
        return (<HTMLElement> element.getElement())
            .dataset.validationAddressNotallowedPattern !== undefined;
    }

    protected static create(element: FormElementComponent): AddressNotAllowedValidation {
        return new AddressNotAllowedValidation(element);
    }

    protected isValid(): boolean {
        const addressNotAllowedRegExp: RegExp =
            regexpFromString(this.validationAddressNotAllowedPattern);

        return this.isEmpty() || !addressNotAllowedRegExp.test(this.element.value);
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }

    protected get validationAddressNotAllowedPattern(): string {
        return this.element.dataset.validationAddressNotallowedPattern;
    }
}
