import { Component } from '@contorion/core/components';
import { componentService } from '@contorion/core/services';

componentService.register('.js-display-variation', (element: HTMLElement) => {
    import (
        /* webpackChunkName: "display-variation" */
        './components/display-variation.component')
        .then(({ DisplayVariationComponent }) => {
            <Component> DisplayVariationComponent.loadByElement(element);
        });
});

componentService.register('.js-cookie-variation', (element: HTMLElement) => {
    import (
        /* webpackChunkName: "cookie-variation" */
        './components/cookie-variation.component')
        .then(({ CookieVariationComponent }) => {
            <Component> CookieVariationComponent.loadByElement(element);
        });
});

componentService.register('.js-component-variation', (element: HTMLElement) => {
    import (
        /* webpackChunkName: "component-variation" */
        './components/component-variation.component')
        .then(({ ComponentVariationComponent }) => {
            <Component> ComponentVariationComponent.loadByElement(element);
        });
});
