import { FormElementComponent } from '../../components/form-element.component';

export const TYPE_ERROR: string = 'error';
export const TYPE_WARNING: string = 'warning';

export abstract class BaseValidation {
    protected static requiresValidation(element: FormElementComponent): boolean {
        return false;
    }

    protected static create(element: FormElementComponent): any {
        return null;
    }

    public static register(element: FormElementComponent): any {
        if (!this.requiresValidation(element)) {
            return;
        }

        return this.create(element);
    }

    protected get element(): HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement {
        return <HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
            this.formElement.getElement();
    }

    constructor(protected formElement: FormElementComponent) {
        this.element.addEventListener('change', this.checkValidity.bind(this));
        this.element.addEventListener('keyup', this.checkValidity.bind(this));
        this.element.addEventListener('blur', this.checkValidity.bind(this));

        this.checkValidity();
    }

    protected abstract isValid(): boolean;

    protected getType(): string {
        return TYPE_ERROR;
    }

    protected abstract getErrorType(): string;

    protected isEmpty(): boolean {
        return /^$/.test(this.element.value);
    }

    protected setInvalid(): void {
        if (this.getType() === TYPE_ERROR) {
            this.formElement.setCustomError(this.getErrorType());

            return;
        }

        if (this.getType() === TYPE_WARNING) {
            this.formElement.setWarning(this.getErrorType());
        }
    }

    protected setValid(): void {
        if (this.getType() === TYPE_ERROR
            && this.getErrorType() === this.formElement.getCustomerError()) {
            this.formElement.setCustomError('');

            return;
        }

        if (this.getType() === TYPE_WARNING
            && this.getErrorType() === this.formElement.getWarning()) {
            this.formElement.setWarning(null);
        }
    }

    protected checkValidity() {
        if (this.isValid()) {
            this.setValid();
        } else {
            this.setInvalid();
        }
    }
}
