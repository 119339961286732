import { Component, componentService } from '@contorion/core';

componentService.register('.js-language-switch', (element: Element) => {
    import (
        /* webpackChunkName: "language-switch" */
        './components/language-switch.component')
        .then(({ LanguageSwitchComponent }) => {
            <Component> LanguageSwitchComponent.loadByElement(element);
        });
});
