import { Component, componentService } from '@contorion/core';

componentService.register('.js-subscription-form', (element: Element) => {
    import (
        /* webpackChunkName: "subscription-form" */
        './components/subscription-form.component')
        .then(({ SubscriptionFormComponent }) => {
            <Component> SubscriptionFormComponent.loadByElement(element);
        });
});
