export const b64DecodeUnicode = function(input: string): string {
    return decodeURIComponent(atob(input).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
};

export const urlBase64ToUint8Array = function(base64String: string) {
    const padding: string = '='.repeat((4 - base64String.length % 4) % 4);
    const base64: string = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData: string = window.atob(base64);
    const outputArray: Uint8Array = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
};
