import { services } from '@contorion/core';

services.register('tracking-scripts-service', () => {
    return import (
        /* webpackChunkName: "third-party-scripts-service" */
        './services/tracking-scripts.service')
        .then(({ trackingScriptsService  }) => {
            return trackingScriptsService ;
        });
});
