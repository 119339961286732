import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-recommendation', (element: Element) => {
    import (
        /* webpackChunkName: "recommendation" */
        './components/recommendation.component')
        .then(({ RecommendationComponent }) => {
            <Component> RecommendationComponent.loadByElement(element);
        });
});
componentService.register('.js-dy-recommendation', (element: Element) => {
    import (
        /* webpackChunkName: "dy-recommendation" */
        './components/dy-recommendation.component')
        .then(({ DYRecommendationComponent }) => {
            <Component> DYRecommendationComponent.loadByElement(element);
        });
});

services.register('recommendation', () => {
    return import (
        /* webpackChunkName: "recommendation-service" */
        './services/recommendation.service')
        .then(({ recommendationService }) => {
            return recommendationService;
        });
});
