import { Component, componentService } from '@contorion/core';

componentService.register('.js-notification', (element: Element) => {
    import (
        /* webpackChunkName: "notification" */
        './components/notification.component')
        .then(({ NotificationComponent }) => {
            <Component> NotificationComponent.loadByElement(element);
        });
});
