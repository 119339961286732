import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-scroll-class-toggler', (element: Element) => {
    import (
        /* webpackChunkName: "scroll-class-toggler" */
        './components/scroll-class-toggler.component')
        .then(({ ScrollClassTogglerComponent }) => {
            <Component> ScrollClassTogglerComponent.loadByElement(element);
        });
});

services.register('scroll-observer-service', () => {
    return import (
        /* webpackChunkName: "scroll-observer" */
        './services/scroll-observer.service')
        .then(({ scrollObserverService }) => {
            return scrollObserverService;
        });
});

componentService.register('.js-directional-class-toggle', (element: Element) => {
    import (
        /* webpackChunkName: "directional-class-toggler" */
        './components/directional-class-toggle.component')
        .then(({ DirectionalClassToggleComponent }) => {
            <Component> DirectionalClassToggleComponent.loadByElement(element);
        });
});
