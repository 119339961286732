import { Component, componentService } from '@contorion/core';

componentService.register('.js-link', (element: Element) => {
    import (
        /* webpackChunkName: "link" */
        './components/link.component')
        .then(({ LinkComponent }) => {
            <Component> LinkComponent.loadByElement(element);
        });
});
