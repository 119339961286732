import {Component, componentService, services} from '@contorion/core';

componentService.register('.js-popup', (element: Element) => {
    import (/* webpackChunkName: "popup" */ './components/popup.component')
        .then(({ PopupComponent }) => {
            <Component> PopupComponent.loadByElement(element);
        });
});
services.register('popup', () => {
    return import (
        /* webpackChunkName: "popup-service" */
        './services/popup.service')
        .then(({ popupService }) => {
            return popupService;
        });
});
