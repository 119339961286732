import {componentService} from './component.service'
import {loggerService} from './logger.service';
import {element as $} from '../helpers';
import {IDYRecommendationWidgetOptions} from "../interfaces";

const RECOMMENDATION_COMPONENT_CLASS: string = 'js-dy-recommendation';
const RECOMMENDATION_WRAPPER_CLASS: string = 'dy-recommendation-widget';
const ATTRIBUTE_STRATEGY_ID: string = 'strategyId';
const ATTRIBUTE_OPTIONS: string = 'options';

class DyRecommendationService {

    public renderWidget(strategyId: number, target: string, options: IDYRecommendationWidgetOptions) {
        try {
            if (!strategyId || !target) {
                return;
            }

            const targetElement = $(target);

            if (targetElement) {
                const parentContainer = document.createElement('div');
                const container = document.createElement('div');
                container.classList.add(RECOMMENDATION_COMPONENT_CLASS, RECOMMENDATION_WRAPPER_CLASS);
                container.dataset[ATTRIBUTE_STRATEGY_ID] = strategyId.toString();
                if (options) {
                    container.dataset[ATTRIBUTE_OPTIONS] = JSON.stringify(options);
                }

                targetElement.insertAdjacentElement(options.position, parentContainer).appendChild(container);
                componentService.load(parentContainer);
            }
        }
        catch (e) {
            loggerService.error(e,
                `Rendering DY recommendation has been failed with [StrategyID]: ${strategyId}, [target]: ${target}`);
        }

    }
}

export const dyRecommendationService: DyRecommendationService = new DyRecommendationService();
