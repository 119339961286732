import { BehaviorSubject } from '../rxjs';

export interface IActiveVariation {
    variationName: string;
    campaignName: string;
}

class VariationService {
    protected currentVariations: BehaviorSubject<Array<IActiveVariation>> = new BehaviorSubject([]);

    public getCurrentVariations(): Array<IActiveVariation> {
        return this.currentVariations.getValue();
    }

    public setCurrentVariation(variationName: string, campaignName: string = null): void {
        const currentVariations: Array<IActiveVariation> = this.getCurrentVariations();

        for (const currentVariation of currentVariations) {
            if (currentVariation.campaignName === campaignName) {
                currentVariation.variationName = variationName;
                this.currentVariations.next(currentVariations);

                return;
            }
        }
        currentVariations.push({ campaignName, variationName });

        this.currentVariations.next(currentVariations);
    }

    public subscribeCurrentVariation(
        subscriber: (currentVariations: Array<IActiveVariation>) => void
    ): void {
        this.currentVariations.subscribe(subscriber);
    }
}

export const variationService = new VariationService();
