import { jsonToFormData } from '../helpers/json-to-form-data';
import {loggerService} from "./logger.service";
import {RequestError} from "../errors";

const CREDENTIALS: RequestCredentials = 'include';
const HEADER_AUTHORIZATION: string = 'Authorization';
const HEADER_XHR_NAME: string = 'X-Requested-With';
const HEADER_XHR_VALUE: string = 'XMLHttpRequest';
const METHOD_POST: string = 'post';
const METHOD_GET: string = 'get';
const METHOD_PUT: string = 'put';
const METHOD_DELETE: string = 'delete';
const PATTERN_AUTH_IN_URL: RegExp = /^https?:\/\/([^:]+:[^@]+@)/;

class RequestService {
    private requestMiddlewares: Array<RequestMiddleware> = [];

    public async request(url: string, method: string, body?: any): Promise<Response> {
        const headers: Headers = new Headers();

        headers.append(HEADER_XHR_NAME, HEADER_XHR_VALUE);

        const options: RequestInit = {
            credentials: CREDENTIALS,
            headers,
            method,
        };

        if (typeof body === 'string') {
            options.body = body;
        } else {
            if (body && !(body instanceof FormData)) {
                body = jsonToFormData(body);
            }

            if (body && body instanceof FormData) {
                options.body = body;
            }
        }

        url += /\?/.test(url) ? '&' : '?';

        for (const requestMiddleware of this.requestMiddlewares) {
            url = requestMiddleware(url);
        }

        if (PATTERN_AUTH_IN_URL.test(url)) {
            url = url.replace(RegExp.$1, '');
            const credentials: string = btoa(RegExp.$1.replace('@', ''));
            headers.append(HEADER_AUTHORIZATION, `Basic ${credentials}`);
        }

        try {
            let response = await fetch(url, options);

            if (!response.ok) {
                const message = `[API Error]: Request failed with status code ${response.status}`;
                loggerService.error(new RequestError(message), `[URL]: ${url}`);
            }
            return response;
        } catch (err) {
            loggerService.error(new RequestError(`[Network Error]: ${err.message}`), `[URL]: ${url}`);
        }
    }

    public post(url: string, body: any): Promise<Response> {
        return this.request(url, METHOD_POST, body);
    }

    public get(url: string): Promise<Response> {
        return this.request(url, METHOD_GET);
    }

    public put(url: string, body: any): Promise<Response> {
        return this.request(url, METHOD_PUT, body);
    }

    public delete(url: string): Promise<Response> {
        return this.request(url, METHOD_DELETE);
    }

    public addRequestMiddleware(requestMiddleware: RequestMiddleware) {
        this.requestMiddlewares.push(requestMiddleware);
    }
}

export const requestService = new RequestService();
