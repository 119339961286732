import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-shopping-list-widget', (element: Element) => {
    import (
        /* webpackChunkName: "shopping-list-widget" */
        './components/shopping-list-widget.component')
        .then(({ ShoppingListWidgetComponent }) => {
            <Component> ShoppingListWidgetComponent.loadByElement(element);
        });
});

componentService.register('.js-shopping-list', (element: Element) => {
    import (
        /* webpackChunkName: "shopping-list" */
        './components/shopping-list.component')
        .then(({ ShoppingListComponent }) => {
            <Component> ShoppingListComponent.loadByElement(element);
        });
});

componentService.register('.js-shopping-list-wrapper', (element: Element) => {
    import (
        /* webpackChunkName: "shopping-list-wrapper" */
        './components/shopping-list-wrapper.component')
        .then(({ ShoppingListWidgetComponent }) => {
            <Component> ShoppingListWidgetComponent.loadByElement(element);
        });
});

services.register('shopping-list-service', () => {
    return import (
        /* webpackChunkName: "shopping-list-service" */
        './services/shopping-list.service')
        .then(({ shoppingListService }) => {
            return shoppingListService;
        });
});

componentService.register('.js-editable-content', (element: Element) => {
    import (
        /* webpackChunkName: "shopping-list-rename" */
        './components/shopping-list-rename.component')
        .then(({ ShoppingListRenameComponent }) => {
            <Component> ShoppingListRenameComponent.loadByElement(element);
        });
});
