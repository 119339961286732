import { FormComponent } from '../components/form.component';
import { validations } from './validations';
import { FormElementComponent } from '../components/form-element.component';
import { BaseValidation } from './validations/base.validation';

export class ValidatorService {
    constructor(protected form: FormComponent) {
        for (const element of this.form.getElements()) {
            this.register(element);
        }
    }

    protected register(element: FormElementComponent): void {
        for (const validationName in validations) {
            if (!validations.hasOwnProperty(validationName)) {
                continue;
            }

            const validation: typeof BaseValidation =
                <typeof BaseValidation> validations[validationName];

            validation.register(element);
        }
    }
}
