import { BehaviorSubject } from '../rxjs';

class FormStepsService {
    protected currentStep: BehaviorSubject<number> = new BehaviorSubject<number>(1);

    public setFormStep(value: number): void {
        this.currentStep.next(value);
    }

    public getFormStep(): number {
        return this.currentStep.getValue();
    }

    public subscribeFormStep(subscriber: () => void): void {
        this.currentStep.subscribe(subscriber);
    }
}

export const formStepsService = new FormStepsService();
