import { Component, componentService, services } from '@contorion/core';
componentService.register('.js-dy-add-to-cart', (element: Element) => {
    import (
        /* webpackChunkName: "dynamic-yield-add-to-cart" */
        './components/dy-add-to-cart.component')
        .then(({ DyAddToCartComponent }) => {
            <Component> DyAddToCartComponent.loadByElement(element);
        });
});

componentService.register('.js-dy-load-event', (element: Element) => {
    import (
        /* webpackChunkName: "dynamic-yield-load-event" */
        './components/dy-load-event.component')
        .then(({ DyLoadEventComponent }) => {
            <Component> DyLoadEventComponent.loadByElement(element);
        });
});

componentService.register('.js-dy-context-sync', (element: Element) => {
    import (
        /* webpackChunkName: "dy-context-sync" */
        './components/dy-context-sync.component')
        .then(({ DyContextSyncComponent }) => {
            <Component> DyContextSyncComponent.loadByElement(element);
        });
});

componentService.register('.js-dy-keystroke-tracker', (element: Element) => {
    import (
        /* webpackChunkName: "js-dy-keystroke-tracker" */
        './components/dy-keystroke-event.component')
        .then(({ DyKeystrokeEventComponent }) => {
            <Component> DyKeystrokeEventComponent.loadByElement(element);
        });
});

componentService.register('.js-dy-view-port', (element: Element) => {
    import (
        /*webpackChunkName: "dy-view-port" */
        './components/dy-view-port.component')
        .then(({DyViewPortComponent}) => {
            <Component> DyViewPortComponent.loadByElement(element);
        });
});

componentService.register('.js-dy-cm2', (element: Element) => {
    import (
        /*webpackChunkName: "dy-cm2" */
        './components/dy-cm2-event.component')
        .then(({ DyCm2Component }) => {
            <Component> DyCm2Component.loadByElement(element);
        });
});

componentService.register('.js-dy-engagement', (element: Element) => {
    import (
        /*webpackChunkName: "dy-engagement" */
        './components/dy-engagement.component')
        .then(({DyEngagementComponent}) => {
            <Component> DyEngagementComponent.loadByElement(element);
        });
});

services.register('dynamic-yield-service', () => {
    return import (/* webpackChunkName: "dynamic-yield-service" */ './services/dynamic-yield.service')
        .then(({ dynamicYieldService }) => {
            return dynamicYieldService;
        });
});
