export const getMapKeys = (map: Map<any, any>) => {
    if (typeof Map.prototype.keys !== 'undefined') {
        return Array.from(map.keys());
    }

    let keys: Array<any> = [];
    map.forEach((value: any, key: any) => {
        keys.push(key);
    });

    return keys;
};
