import './promise';
import './object-assign';

const polyfills: Array<Promise<any>> = [];

/**
 * @deprecated Do not use this method for new features.
 * This was only for IE. We don't support IE anymore and this polyfill should be removed eventually
 * as our IE traffic depleted close to zero
 */
if (typeof Element.prototype.remove === 'undefined') {
    polyfills.push(
        import (/* webpackChunkName: "element-remove-polyfill" */
            './element-remove')
    );
}

if (typeof window.fetch === 'undefined') {
    polyfills.push(
        import (/* webpackChunkName: "fetch-polyfill" */
            './fetch')
    );
}

try {
    const url = new URL(window.location.href);
} catch (e) {
    polyfills.push(
        import (/* webpackChunkName: "url-polyfill" */
            './url')
    );
}

if (!('IntersectionObserver' in window)) {
    polyfills.push(
        import (/* webpackChunkName: "intersection-observer-polyfill" */
            './intersection-observer')
    );
}

/**
 * @deprecated Do not use this method for new features.
 * This was only for IE. We don't support IE anymore and this polyfill should be removed eventually
 * as our IE traffic depleted close to zero
 */
if (!('content' in document.createElement('template'))) {
    polyfills.push(
        import (/* webpackChunkName: "template-polyfill" */
            './template')
    );
}

export default polyfills;
