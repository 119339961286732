import { element as $, services, cookies, hash } from '@contorion/core';
import { IVivianService } from '../vivian/interfaces/vivian-service.interface';

const ID_BAIT: string = 'ad-0';
const ID_COOKIE_BAR: string = 'footer_tc_privacy';
const VALUE_DISPLAY: string = 'none';
const VALUE_VISIBILITY: string = 'hidden';
const VCT_REF_ACTIVE_PREFIX: string = 'active_';
const VCT_REF_TAGCOMMANDER: string = 'tagcommander';
const VCT_REF_ADBLOCKER: string = 'adblocker';
const VCT_REF_COOKIE_BAR: string = 'cookiebar';
const VALUE_ACTIVE: string = '1';
const VALUE_INACTIVE: string = '0';
const COOKIE_EXPIRES_TRACKED_SESSION_ID: number = 604800000;
const COOKIE_TRACKED_HASH: string = 'vivianTrackingHash';
const COOKIE_PATH: string = '/';

class AdblockTrackerComponent {

    protected vivianService: IVivianService;

    protected get isAdBlockerActive(): boolean {
        return this.detectElementBlocked(this.createTargetElement(ID_BAIT));
    }

    protected get trackAdblocker(): string {
        return this.createTrackingValue(VCT_REF_ADBLOCKER, this.isAdBlockerActive);
    }

    protected get trackTagCommander(): string {
        return this.detectTagCommanderBlocked(VCT_REF_TAGCOMMANDER);
    }

    protected get trackCookieBar(): string {
        const activated = !this.detectElementBlocked(<HTMLElement> $(`#${ID_COOKIE_BAR}`));

        return this.createTrackingValue(VCT_REF_COOKIE_BAR, activated);
    }

    protected get trackingValue(): string {
        return [
            this.trackAdblocker,
            this.trackTagCommander,
            this.trackCookieBar,
        ]
            .filter((value: string) => value !== null)
            .join('&')
            .toString();
    }

    protected get currentTrackingHash(): string {
        return hash(this.trackingValue).toString();
    }

    protected get hasTrackingNotChanged(): boolean {
        return cookies.get(COOKIE_TRACKED_HASH) === this.currentTrackingHash;
    }

    constructor() {
        this.init();
    }

    protected async init(): Promise<any> {
        window.contorion = window.contorion || {};
        window.contorion.isAdBlockerActive = this.isAdBlockerActive;

        if (this.hasTrackingNotChanged) {
            return;
        }

        this.vivianService = await services.get('vivian-service');
        this.vivianService.track(this.trackingValue);
        this.updateTrackingHashCookie();
    }

    protected updateTrackingHashCookie(): void {
        cookies.remove(COOKIE_TRACKED_HASH);
        cookies.add(
            COOKIE_TRACKED_HASH,
            this.currentTrackingHash,
            COOKIE_EXPIRES_TRACKED_SESSION_ID,
            COOKIE_PATH
        );
    }

    protected createTrackingValue(key: string, isActivated: boolean): string {
        return VCT_REF_ACTIVE_PREFIX
            .concat(key)
            .concat('=')
            .concat(isActivated ? VALUE_ACTIVE : VALUE_INACTIVE);
    }

    protected createTargetElement(targetId: string): HTMLElement {
        const newTargetElement: HTMLElement = document.createElement('div');

        newTargetElement.id = targetId;

        document.body.appendChild(newTargetElement);

        return newTargetElement;
    }

    protected detectElementBlocked(targetElement: HTMLElement): boolean {
        if (!targetElement || window.getComputedStyle === undefined) {
            return null;
        }

        const computedStyle: CSSStyleDeclaration = window.getComputedStyle(targetElement, null);

        return computedStyle
            && computedStyle.display === VALUE_DISPLAY
            || computedStyle.visibility === VALUE_VISIBILITY;
    }

    protected detectTagCommanderBlocked(key: string): string {
        if ((<any> window).tc_vars === undefined || (<any> window).tc_privacy_used === undefined) {
            return null;
        }

        return this.createTrackingValue(key, (<any> window).tc_privacy_used);
    }
}

export const adblockTracker = new AdblockTrackerComponent();
