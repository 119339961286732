export function toggleClass(element: HTMLElement, className: string, add?: boolean): void {
    if (add === undefined) {
        add = !element.classList.contains(className);
    }

    if (add) {
        element.classList.add(className);
    } else {
        element.classList.remove(className);
    }
}
