import { BaseValidation } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';

const ERROR_TYPE: string = 'vat-id';
const VAT_ID_LENGTH: number = 11;

export class VatIdValidation extends BaseValidation {
    protected static requiresValidation(element: FormElementComponent): boolean {

        return (<HTMLElement> element.getElement())
            .dataset.validationVatId !== undefined;
    }

    protected static create(element: FormElementComponent): VatIdValidation {
        return new VatIdValidation(element);
    }

    protected isValid(): boolean {
        if (!VatIdValidation.requiresValidation(this.formElement)) {
            return true;
        }

        return !this.isEmpty()
            && this.isLengthValid()
            && this.isNumerical();
    }

    protected isLengthValid(): boolean {
        return this.element.value.length === VAT_ID_LENGTH;
    }

    protected isNumerical(): boolean {
        const value = this.element.value;

        if (typeof value !== 'string') {
            return false;
        }

        const num = Number(value);

        return Number.isInteger(num) && num > 0;
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }
}
