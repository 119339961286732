import { BaseValidation } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';

const ERROR_TYPE: string = 'maxlength';

export class MaxlengthValidation extends BaseValidation {
    protected get maxlength(): number {
        return parseInt(this.element.dataset.validationMaxlength, 10);
    }

    protected static requiresValidation(element: FormElementComponent): boolean {
        return (<HTMLElement> element.getElement()).dataset.validationMaxlength !== undefined;
    }

    protected static create(element: FormElementComponent): MaxlengthValidation {
        return new MaxlengthValidation(element);
    }

    protected isValid(): boolean {
        return this.isEmpty() || this.element.value.length <= this.maxlength;
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }
}
