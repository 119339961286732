import { BaseValidation } from './base.validation';
import { FormElementComponent } from '../../components/form-element.component';
import { element as $ } from '../../helpers';

const ERROR_TYPE: string = 'confirmation';

export class ConfirmationValidation extends BaseValidation {
    protected get targetSelector(): string {
        return this.element.dataset.validateConfirmation;
    }

    protected get target(): HTMLInputElement {
        return <HTMLInputElement> $(this.targetSelector);
    }

    protected static requiresValidation(element: FormElementComponent): boolean {
        return (<HTMLInputElement> element.getElement()).dataset.validateConfirmation !== undefined;
    }

    protected static create(element: FormElementComponent): ConfirmationValidation {
        return new ConfirmationValidation(element);
    }

    protected isValid(): boolean {
        return this.element.value === this.target.value;
    }

    protected getErrorType(): string {
        return ERROR_TYPE;
    }
}
