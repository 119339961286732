import { Component, componentService, services } from '@contorion/core';

services.register('navigation', () => {
   return import (
       /* webpackChunkName: "navigation-service" */
       './services/navigation.service')
       .then(({ navigationService }) => {
           return navigationService;
       });
});

services.register('navigation-menu-item', () => {
    return import (
        /* webpackChunkName: "navigation-menu-item-service" */
        './services/navigation-menu-item.service')
        .then(({ navigationMenuItemService }) => {
            return navigationMenuItemService;
        });
});

componentService.register('.js-navigation', (element: Element) => {
    import (
        /* webpackChunkName: "navigation" */
        './components/navigation.component')
        .then(({ NavigationComponent }) => {
            <Component> NavigationComponent.loadByElement(element);
        });
});

componentService.register('.js-navigation-mobile', (element: Element) => {
    import (
        /* webpackChunkName: "navigation-mobile" */
        './components/navigation-mobile.component')
        .then(({ NavigationMobileComponent }) => {
            <Component> NavigationMobileComponent.loadByElement(element);
        });
});

componentService.register('.js-navigation .js-menu', (element: Element) => {
    import (
        /* webpackChunkName: "navigation-menu" */
        './components/navigation-menu.component')
        .then(({ NavigationMenuComponent }) => {
            <Component> NavigationMenuComponent.loadByElement(element);
        });
});

componentService.register('.js-navigation-highlighter', (element: Element) => {
    import (
        /* webpackChunkName: "navigation-highlighter" */
        './components/navigation-highlighter.component')
        .then(({ NavigationHighlighterComponent }) => {
            <Component> NavigationHighlighterComponent.loadByElement(element);
        });
});

componentService.register('.js-burger-menu', (element: Element) => {
    import (
        /* webpackChunkName: "burger-menu" */
        './components/burger-menu.component')
        .then(({ BurgerMenuComponent }) => {
            <Component> BurgerMenuComponent.loadByElement(element);
        });
});

componentService.register('.js-layover-menu', (element: Element) => {
    import (/* webpackChunkName: "js-layover-menu" */
        './components/layover-menu.component')
        .then(({LayoverMenuComponent}) => {
            <Component> LayoverMenuComponent.loadByElement(element, '.js-layover-menu');
        });
});
