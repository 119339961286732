import { Component, componentService } from '@contorion/core';

componentService.register('.js-offer-request-form', (element: Element) => {
    import (
        /* webpackChunkName: "offer-request-form" */
        './components/offer-request-form.component'
        ).then(({ OfferRequestFormComponent }) => {
            <Component> OfferRequestFormComponent.loadByElement(element);
        });
});
