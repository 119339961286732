import { Component, componentService, services } from '@contorion/core';

componentService.register('.js-dropdown', (element: Element) => {
    import (/* webpackChunkName:  "dropdown"*/ './components/dropdown.component')
        .then(({ DropdownComponent }) => {
            <Component> DropdownComponent.loadByElement(element);
        });
});

services.register('dropdown-service', () => {
    return import (
        /* webpackChunkName: "dropdown-service" */
        './services/dropdown.service'
    )
        .then(({ dropdownService }) => {
            return dropdownService;
        });
});
