import { Component, componentService } from '@contorion/core';

componentService.register('.js-expandable', (element: Element) => {
    import (
        /* webpackChunkName: "expandable" */
        './components/expandable.component')
        .then(({ ExpandableComponent }) => {
            <Component> ExpandableComponent.loadByElement(element);
        });
});
